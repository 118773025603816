@import "../../../../../../assets/scss/base/variables";

.modal-content {
  .modal-inputs {
    margin: 0 0 25px;
  }
  .modal-box {
    margin: 0 0 25px;
  }
  .btn {
    padding: 12px 30px;
  }
}
