@import "../../../assets/scss/base/variables";

.dropdown {
  .Collapsible {
    font-size: 16px;
    padding: 0;
  }
  .Collapsible__trigger {
    border-bottom: none;
    background: #e1e7f1;
    color: $dark-blue;
    font-weight: 500;
    padding: 13px 20px;
    &:after {
      right: 20px;
    }
    .content {
      background: $light-gray;
      padding: 13px 20px;
    }
  }
  .Collapsible__trigger:hover {
    background-color: #37444e;
    color: #71cc51;
  }
  .Collapsible__contentOuter {
    padding: 0;
  }
  .Collapsible__contentInner {
    padding: 20px;
    overflow-y: auto !important;
    background: $light-gray;
  }
}
