@import "../../../../../assets/scss/base/variables";
.potential-section {
  background-color: #ffffff;
  height: 100%;
  position: relative;
  padding: 10px 20px 15px 20px;
  h3 {
    text-align: center;
    margin-bottom: 10px;
    color: $blue;
    font-weight: 900;
  }
  .react-slideshow-container {
    .nav {
      margin: 0;
      position: absolute;
      bottom: 0;
      background: transparent;
      border-radius: 0;
      span {
        border-color: transparent;
        border-style: none;
        &:hover {
          opacity: 0.5;
        }
      }
      &.disabled {
        cursor: default;
        pointer-events: none;
      }
      &:first-of-type {
        margin: 0;
        right: 30px;
        span {
          width: 0;
          height: 0;
          border-left: 7px solid transparent;
          border-right: 7px solid transparent;
          border-bottom: 14px solid $green;
          transform: rotate(-90deg);
        }
        &.disabled {
          span {
            border-bottom: 14px solid $gray;
          }
        }
      }
      &:last-of-type {
        margin: 0;
        right: 0;
        span {
          width: 0;
          height: 0;
          border-left: 7px solid transparent;
          border-right: 7px solid transparent;
          border-bottom: 14px solid $green;
          transform: rotate(90deg);
        }
        &.disabled {
          span {
            border-bottom: 14px solid $gray;
          }
        }
      }
    }
  }
  .slide-list {
    list-style: none;
    padding: 0;
    margin: 0;
    li {
      margin-bottom: 10px;
      span {
        display: block;
        &.title {
          color: $blue;
          font-size: 14px;
          font-weight: 600;
          margin-bottom: 3px;
        }
        &.description {
          font-weight: 500;
          color: $black;
          font-size: 12px;
        }
      }
      .item-holder {
        display: flex;
        justify-content: flex-start;
        align-items: center;
      }
      &:last-of-type {
        margin-bottom: 0;
      }
      .icon {
        color: $blue;
        font-size: 25px;
        margin-right: 10px;
      }
    }
  }
}
