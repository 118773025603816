@import "../../../assets/scss/base/variables";

.radio-button {
  .custom-radio {
    cursor: pointer;
    width: 15px;
    height: 15px;
    display: inline-block;
    cursor: pointer;
    position: relative;
    background: $white;
    border: 2px solid $light-blue;
    margin-right: 4px;
    border-radius: 10px;
    transition:
      opacity 0.3s,
      border-color 0.3s,
      background-color 0.3s;
    &:after {
      content: "";
      top: 2px;
      left: 2px;
      position: absolute;
      background-color: $green;
      opacity: 0;
      color: $white;
      transition: opacity 0.3s;
      width: 7px;
      height: 7px;
      border-radius: 10px;
    }
    &.checked {
      &:after {
        opacity: 1;
      }
    }
    &:hover {
      &:after {
        opacity: 0.7;
      }
    }
    input {
      visibility: hidden;
    }
  }
}
