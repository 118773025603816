@import "../../../assets/scss/base/variables";

.callSupport {
  display: flex;
  width: 5%;
  border-left: 1px solid #cbd5e7;
  border-right: 1px solid #cbd5e7;
  > * {
    margin: auto;
  }
  .icon {
    cursor: pointer;
    font-size: 23px;
    color: $green;
  }
}
