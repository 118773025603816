@import "../../../assets/scss/vendors/include-media";
@import "../../../assets/scss/base/variables";

.PageHeader {
  margin-bottom: 10px;
  @include media("<tablet") {
    display: inherit;
  }
}
.callSupport {
  @include media("<tablet") {
    display: none;
  }
}
.single-tab-modal {
  header {
    background-color: $green;
  }
  .dropdown {
    margin: 0;
    .single-tab-modal-content {
      background-color: $white;
      border: 1px solid $light-blue;
      color: $dark-blue;
      padding: 20px;
      label {
        cursor: pointer;
        color: #4a63a1;
      }
      .label-disable {
        cursor: auto;
      }
      .step-input {
        margin: 10px 0;
        width: 80%;
        border: 1px solid $light-blue;
      }
      .quota-credit {
        display: flex;
        margin-bottom: 20px;
        i {
          font-size: 14px;
        }
        .export-margin {
          margin: 7px 0;
        }
        .export-row {
          display: flex;
        }
        .export-div {
          max-width: 235px;
        }
        .icon-propos {
          color: $dark-blue;
        }
        .step-input {
          width: 100px;
          height: 33px;
          margin: 0 10px;
          padding: 0.4em 0.7em;
        }
      }
      .quota-credit-input {
        .small-text {
          font-size: 14px;
        }
      }
      .step-title {
        font-size: 16px;
        color: $dark-blue;
        font-weight: 400;
      }
      .sub-text {
        margin: 5px 0 15px;
        font-size: 14px;
      }
      .main-checkbox {
        margin: 20px 0 30px;
      }
      .sub-checkbox {
        margin: 20px 0 30px 20px;
      }
      .sub-checkbox:last-child {
        margin: 20px 0 0 20px;
        .sub-text {
          margin: 5px 0 10px;
        }
      }
      .custom-checkbox-disable {
        background-color: #f1f3fc;
        border-color: #f1f3fc;
      }
      .custom-checkbox-disable:after {
        color: #37444e;
      }
    }
    .link-row {
      display: flex;
      align-items: baseline;
    }
    .icon {
      color: $green;
      margin-right: 5px;
      font-size: 16px;
    }
    .link-entry {
      margin-left: 25px;
      font-weight: bold;
      font-size: 16px;
      position: relative;
      @include media("<tablet") {
        margin-top: 5px;
        margin-left: 0;
      }
      &:after {
        content: "";
        position: absolute;
        bottom: -3px;
        left: 0;
        width: 0;
        height: 3px;
        background-color: $green;
        -webkit-transition: width 0.3s;
        transition: width 0.3s;
      }
      &:hover {
        color: #32a31a;
        &:after {
          width: 100%;
        }
      }
    }

    .link-icon {
      margin: 20px 0 10px;
      display: flex;
      width: fit-content;
    }

    .link-tooltip {
      position: relative;
    }

    .link-tooltip .tooltiptext {
      visibility: hidden;
      width: 280px;
      background-color: $dark-blue;
      color: $white;
      text-align: center;
      padding: 5px 0;
      border-radius: 6px;
      position: absolute;
      z-index: 1;
      top: -80px;
      right: -50%;
      margin-left: -60px;
      opacity: 0;
      transition: opacity 0.3s;
      font-size: 14px;
    }

    .link-tooltip .tooltiptext::after {
      content: "";
      position: absolute;
      top: 100%;
      left: 30%;
      margin-left: -5px;
      border-width: 5px;
      border-style: solid;
      border-color: $dark-blue transparent transparent transparent;
    }

    .link-tooltip:hover .tooltiptext {
      visibility: visible;
      opacity: 1;
    }
    .select {
      max-width: 340px;
      width: 100%;
      margin: 20px 0 0;
      .react-select__control {
        min-height: 0;
        border-radius: 0;
        border: none;
        cursor: pointer;
        background-color: $light-blue;
      }

      .react-select__control--is-focused {
        box-shadow: none;
        border-radius: 0;

        &:hover {
          border-color: $black;
        }

        .react-select__indicators {
          &:after {
            color: $white;
          }
        }
      }

      .react-select__control--menu-is-open {
        .react-select__indicators {
          &:after {
            transform: rotate(180deg);
          }
        }
      }

      .react-select__single-value {
        color: $blue;
        font-weight: 600;
      }

      .react-select__value-container {
        padding: 8px 15px;
      }

      .react-select__menu {
        border-radius: 0;
        background-color: $light-blue;
        max-width: 340px;
        width: 100%;
        position: inherit;
        .react-select__menu-list {
          overflow-y: scroll;
          max-height: 150px;
          .react-select__option {
            cursor: pointer;

            &:hover {
              background-color: $light-gray;
            }
          }

          .react-select__option--is-selected {
            background-color: $white;
            color: $black;
          }
        }
      }
    }
  }
}

.admin-actions-holder {
  padding: 20px;
}

.admin-tabs {
  display: flex;
  flex: auto;
  align-items: center;
  //justify-content: space-evenly;
  margin: 0 10px;
  margin-left: 20px;
  column-gap: 2%;
  height: 45px;
  transform: translateY(1px);
  @media (max-width: "460px") {
    margin-left: 10px;
  }
  .link-entry {
    display: inline-block;
    text-overflow: Ellipsis;
    white-space: nowrap;
    cursor: pointer;
    color: $light-blue;
    font-weight: 600;
    line-height: 16px;
    @media (max-width: "1200px") {
      font-size: 13px;
    }
    @media (max-width: "1080px") {
      font-size: 12px;
    }
    @media (max-width: "925px") {
      font-size: 11px;
    }
    @media (max-width: "875px") {
      font-size: 10px;
    }
    @media (max-width: "830px") {
      font-size: 9px;
    }
    @media (max-width: "768px") {
      font-size: 14px;
    }
    @media (max-width: "460px") {
      font-size: 10px;
    }
    font-size: 16px;
    position: relative;
    &:after {
      content: "";
      position: absolute;
      bottom: -13px;
      left: 0;
      width: 0;
      height: 3px;
      background-color: $green;
      -webkit-transition: width 0.3s;
      transition: width 0.3s;
    }
    &:hover {
      color: #32a31a;
      &:after {
        width: 100%;
      }
    }
  }
  .active {
    color: #32a31a;
    &:after {
      width: 100%;
    }
  }
}

.admin-settings {
  .settings-info {
    margin-top: 5px;
    overflow-y: hidden;
  }
  .settings-info-plan {
    margin-top: 30px;
    overflow-y: hidden;
  }
  .settings-info-bold {
    font-weight: bold;
  }

  th {
    width: 33%;
  }
  tr {
    vertical-align: top;

    ul {
      list-style: none;
      margin-left: 0;
      padding-left: 0;
      .icon {
        font-size: 15px;
        color: #37444e;
        margin-right: 5px;
      }
      li {
        margin: 5px 0;
      }
    }
    .dashboard-cta-container {
      margin-top: 50px;
      margin-bottom: 10px;
    }
  }
}

.admin-settings {
  margin-bottom: 50px;
  .sub-title {
    display: flex;
    align-items: center;
    margin: 5px 10px;
    justify-content: space-between;
    .info {
      .icon {
        margin-right: 10px;
      }
    }
    .dates {
      display: flex;
      align-items: center;
      .label {
        margin-right: 10px;
        margin-left: 20px;
      }
      .react-datepicker-wrapper {
        input {
          max-width: 120px;
        }
      }
      .react-datepicker-popper {
        z-index: 3;
      }
    }
  }
  .btn-primary-small {
    margin: 5px 5px 5px 15px;
    padding: 10px 10px;
    font-size: 12px;
  }
  .btn-download {
    background-color: transparent;
    color: #32a31a;
    border: none;
    padding: 0;
    font-size: 25px;
    margin: 0 7px;
    font-family: "icomoon" !important;
    font-style: normal;
    font-weight: normal;
    font-feature-settings: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
  }
  .table-holder {
    margin: 20px 10px;
    padding: 0;
    .credit {
      width: 12%;
    }
    .name {
      width: 15%;
    }
    .total {
      font-weight: bold;
    }
    .stat-header {
      display: flex;
      align-items: center;
      .label {
        margin-right: 10px;
      }
      .sort {
        display: grid;
        .icon {
          margin: 0;
          padding: 0;
          opacity: 1;
          &.asc {
            transform: rotate(180deg);
          }
        }
      }
    }
  }
  .stat-export {
    display: flex;
    .stat-export-dataviz {
      background-color: #fff;
      width: 500px;
      height: 300px;
    }
  }
}
.export-pack .table-wrapper table tbody {
  td {
    &:last-child:after {
      width: -webkit-fill-available;
    }
    &:last-of-type {
      border-right: 2px solid $light-blue;
    }
  }
  .dataviz {
    &:last-child:after {
      border-bottom: none;
    }
  }
}

.admin-user {
  .error-input {
    margin: 0;
    color: #f7786b;
    font-weight: bold;
    font-size: 0.8em;
  }
}

.dashboard-account-component .my-account__box-list {
  margin-top: 15px;
}

.my-account__label {
  min-width: 230px;
}
.title-links-container.my-account {
  justify-content: space-between;
  padding: 0 5px;
}

.admin-dashboard {
  display: flex;
  align-items: center;
  .dates {
    display: flex;
    align-items: center;
  }
  .react-datepicker-wrapper {
    input {
      max-width: 120px;
    }
  }
  .label {
    margin-left: 10px;
    margin-right: 10px;
  }
  .btn-primary-small {
    margin: 5px 5px 5px 15px;
    padding: 10px 10px;
    font-size: 12px;
  }
}
.card-item {
  margin-bottom: 20px;
}
