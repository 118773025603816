@import "../../../../assets/scss/base/variables";
@import "../../../../assets/scss/vendors/include-media";
.dashboard-contact-component {
  margin-top: 35px;
  z-index: 2;
  width: 100%;
  padding: 15px;
  .contact-title {
    font-size: 22px;
    color: $blue;
    font-weight: bold;
  }
  .contact-informations {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 15px;
    background-color: #fff;
    padding: 15px;
    box-sizing: border-box;
    .contact-name-status {
      .name {
        color: $blue;
        font-weight: bold;
        margin-bottom: 4px;
      }
    }
    @include media("<tablet") {
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
    }

    .contact-links {
      font-size: 20px;
      .link-entry {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin: 3px 0;
        .value {
          font-size: 16px;
          @include media("<tablet") {
            order: 1;
          }
        }
        a {
          margin-left: 5px;
          span {
            margin: 0 5px;
            cursor: pointer;
          }
          @include media("<tablet") {
            margin-left: 0;
          }
        }
        @include media("<tablet") {
          justify-content: flex-start;
        }
      }
    }

    .contact-picture {
      width: 60px;
      height: 60px;
      border-radius: 100%;
      overflow: hidden;
      img {
        object-fit: cover;
      }
    }
  }
}
