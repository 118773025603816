@import "../../../assets/scss/base/variables";
@import "../../../assets/scss/vendors/include-media";

.edit-input {
  width: 100%;
}

.display-mobile {
  @include media("<tablet") {
    display: none;
  }
}

.table-holder {
  font-size: 14px;
}
.table-holder .table-wrapper table thead th {
  padding: 1rem;
}

th {
  text-align: left;
  vertical-align: baseline;
  cursor: pointer;
}

.actionDiv {
  display: flex;
  > span {
    margin: auto 0.2rem;
    padding: 0.1rem;
    border-radius: 4px;
    font-size: 0.8rem;
    color: $dark-blue;
    &.icon-confirm {
      border: 1px solid $dark-blue;
      color: transparent;
    }
    &.icon-close {
      font-size: 1.2rem;
      color: $dark-blue;
    }
    &.action {
      cursor: pointer;
    }
    &.checked {
      color: $green;
      border-color: $green;
      font-weight: 700;
      margin: auto;
    }
    &.redCross {
      color: $red-base;
      margin: auto;
    }
    &.icon-view {
      font-size: 20px;
    }
  }
}
