.modal-tree-group {
  .select-container {
    justify-content: center;
    padding-bottom: 15px;
    margin-bottom: 30px;
    border-bottom: 2px solid #b6c4dd;
  }
  .range-component {
    .main-container {
      justify-content: center;
    }
  }
}
