@import "../../../../../../assets/scss/vendors/include-media";
@import "../../../../../../assets/scss/base/variables";

.modal-list {
  padding: 20px 0;
  .checkboxes-wrapper {
    padding: 0;
    margin: 0;
  }
  .checkboxes-list {
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    ul {
      padding: 0;
      //margin-bottom: 20px;
    }
  }
}
#tabpanel-anneeBilan {
  .modal {
    background-color: #fff;
    border: 1px solid $light-blue;
  }
  .checkboxes-list {
    .col-md-4 {
      flex-basis: 100%;
      max-width: 100%;
      ul {
        li {
          display: inline-block;
          margin-bottom: 17px;
          label {
            background: #b6c4dd;
            padding: 7px 20px;
            border-radius: 25px;
            color: #4a63a1;
            margin-right: 12px;
            margin-left: 12px;
            &:hover,
            &.checked {
              background-color: $green;
              color: $white;
            }
            .custom-checkbox {
              width: 0;
              height: 0;
              display: inline-block;
              cursor: pointer;
              position: relative;
              background: none;
              border: none;
              margin-right: 0;
              transition: none;
              &:after {
                opacity: 0;
              }
            }
          }
        }
      }
    }
  }
}
