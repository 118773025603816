@import "../../../../../assets/scss/base/variables";
@import "../../../../../assets/scss/vendors/include-media";
.confirm-list-info {
  padding: 30px 40px;
  @include media("<tablet") {
    padding: 20px;
  }
  p {
    color: $dark-blue;
    font-size: 14px;
  }
  h3 {
    color: $blue;
    margin-bottom: 20px;
    font-weight: 600;
  }
}

.white-section {
  padding: 35px 15px;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: $white;
  margin-bottom: 20px;
  &.centered {
    text-align: center;
  }
  .btn {
    margin-bottom: 30px;
  }
  .link {
    position: relative;
    background: transparent;
    padding: 0;
    margin: 0;
    border: none;
    color: $green;
    font-weight: 600;
    margin-bottom: 10px;
    padding-bottom: 2px;
    &:after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 2px;
      background-color: $green;
      transition: width 0.3s;
    }
    &:hover {
      opacity: 0.75;
      &:after {
        width: 0;
      }
    }
  }
}

.text-holder-confirmation {
  display: flex;
  align-items: center;
  margin-bottom: 25px;
  .icon {
    margin-right: 30px;
    font-size: 40px;
    font-weight: 300;
    padding: 15px;
    border: 1px solid $blue;
    border-radius: 100%;
    color: $blue;
    @include media("<tablet") {
      display: none;
    }
  }
  p {
    margin-bottom: 0;
  }
}
.support-section {
  width: 72%;
  @include media("<tablet") {
    width: 100%;
  }
}
.detail-info {
  padding: 30px 40px;
  header {
    padding: 0;
    h2 {
      max-width: 100%;
    }
  }
}
.contact-info-block {
  margin-bottom: 40px;
  display: flex;
  address {
    padding: 0 70px 0 0;
    h4 {
      color: $blue;
      font-size: 16px;
    }
    a {
      font-weight: 600;
    }
    a.contact-info-block-telephone {
      margin-top: 10px;
      display: block;
    }
    p {
      margin: 0 0 0.5em;
    }
    font-style: normal;
  }
}

.company-item {
  margin-bottom: 30px;
  > img {
    margin-bottom: 10px;
  }
  .link {
    display: flex;
    align-items: center;
    font-weight: 600;
    .icon {
      margin-right: 10px;
    }
  }
}

.questions-list,
.social-list-section {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 25px;
  @include media("<tablet") {
    flex-wrap: wrap;
  }
  li {
    padding: 0 17px;
    @include media("<tablet") {
      width: 50%;
      margin: auto;
      margin-top: 10px;
    }
    &:first-child {
      padding-left: 0;
      @include media("<tablet") {
        padding: inherit;
      }
    }
    &:last-child {
      padding-right: 0;
      @include media("<tablet") {
        padding: inherit;
      }
    }
    .icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 70px;
      height: 70px;
      font-size: 32px;
      line-height: 0;
      border-radius: 100%;
      border: 2px solid $green;
      margin: auto;
    }
    a {
      &:hover {
        .icon {
          color: $light-brand;
          border-color: $light-brand;
        }
      }
    }
  }
}
