@import "../../../assets/scss/vendors/include-media";
@import "../../../assets/scss/base/variables"; // we include this to use $light-blue variable

.edit-input {
  width: 100%;
}

.display-mobile {
  @include media("<tablet") {
    display: none;
  }
}

.table-section {
  .table-holder .table-wrapper table tbody tr {
    &.hidden {
      display: none;
    }
    &.open {
      td {
        &:not(:first-child):after {
          border-bottom: none !important;
        }
      }
    }
    &.crm-report {
      font-size: 14px;
      background-color: #FCFCFE;
      border-top: 2px dotted $light-blue;
      div.crm-report-container {
        display: grid;
        margin-left: 45px;
        margin-bottom: 10px;

        .crm-report-info-block {
          display: inline-flex;
          flex-direction: column;
          margin-top: 15px;
        }

        .crm-report-info-line {
          display: inline-flex;
        }

        label {
          color: #37444E;
          &.export {
            // width: 25%;
          }
          // display: inline-block;
        }

        span {
          &.value {
            margin-left: 5px;
          }

          &.value.type-export {
            color: #32A31A;
          }

          &.value.rejected:not(.empty):after {
            content: "\e920";
            font-family: "icomoon";
            padding-left: 0.5rem;
          }

          &.value.nb {
            color: #32A31A;
          }

          &.value.rejected {
            color: #F8786B;
            cursor: pointer;
          }
        }
      }
    }
  }

  .mylist-url {
    cursor: pointer;
  }
  .first-column {
    width: 30px;
  }
  span.crm-accordion {
    display: inline-block;
    cursor: pointer;
    margin-right: 20px;
    margin-left: 10px;

    &:after {
      content: "\e91C";
      font-family: "icomoon";
    }
    &.open {
      transform: rotate(180deg);
    }
  }

  .ul-element-list {
    list-style: none;
    margin-left: 0;
    padding-left: 0;
  }
  .error-lines {
    display: flex;
    align-items: center;
    .icon-on-load {
      color: #d22e26;
      font-size: 20px;
      padding-left: 5px;
    }
  }
  .icon {
    font-size: 25px;
  }
  .white-borders {
    z-index: 1;
  }

  .disabled-send-to-mcampaign {
    color: #999 !important;
    opacity: 0.3;
  }

  .icon-mail-to-2:before {
    font-weight: bold;
  }
}

.lds-spinner {
  color: #32a31a;
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
  transform: scale(0.4);
}
.lds-spinner div {
  transform-origin: 40px 40px;
  animation: lds-spinner 1.2s linear infinite;
}
.lds-spinner div:after {
  content: " ";
  display: block;
  position: absolute;
  top: 3px;
  left: 37px;
  width: 6px;
  height: 18px;
  border-radius: 20%;
  background: #32a31a;
}
.lds-spinner div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -1.1s;
}
.lds-spinner div:nth-child(2) {
  transform: rotate(30deg);
  animation-delay: -1s;
}
.lds-spinner div:nth-child(3) {
  transform: rotate(60deg);
  animation-delay: -0.9s;
}
.lds-spinner div:nth-child(4) {
  transform: rotate(90deg);
  animation-delay: -0.8s;
}
.lds-spinner div:nth-child(5) {
  transform: rotate(120deg);
  animation-delay: -0.7s;
}
.lds-spinner div:nth-child(6) {
  transform: rotate(150deg);
  animation-delay: -0.6s;
}
.lds-spinner div:nth-child(7) {
  transform: rotate(180deg);
  animation-delay: -0.5s;
}
.lds-spinner div:nth-child(8) {
  transform: rotate(210deg);
  animation-delay: -0.4s;
}
.lds-spinner div:nth-child(9) {
  transform: rotate(240deg);
  animation-delay: -0.3s;
}
.lds-spinner div:nth-child(10) {
  transform: rotate(270deg);
  animation-delay: -0.2s;
}
.lds-spinner div:nth-child(11) {
  transform: rotate(300deg);
  animation-delay: -0.1s;
}
.lds-spinner div:nth-child(12) {
  transform: rotate(330deg);
  animation-delay: 0s;
}
@keyframes lds-spinner {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.spinner-table {
  position: relative;
}

.spinner-container {
  position: absolute;
  top: -6px;
  right: 20px;
  width: 25%;
}
