@import "../../../../../../assets/scss/base/variables";

.number-modal {
  .modal-content {
    margin-bottom: 25px;
  }
  .btn {
    padding: 12px 30px;
  }
}

.unit-euro {
  .input-holder {
    &:after {
      content: "\20AC";
    }
  }
}

.unit-percent {
  .input-holder {
    &:after {
      content: "\0025";
    }
  }
}
.form-holder {
  display: flex;
  align-items: center;
}
.decorator {
  padding: 0 10px;
}
