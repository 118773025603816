@import "../../../assets/scss/base/variables";
@import "../../../assets/scss/vendors/include-media";

.validated-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.75);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  z-index: 2;
  visibility: hidden;
  opacity: 0;
  transition: all 0.4s;

  &.open {
    opacity: 1;
    visibility: visible;
  }

  .validated-title {
    color: $green;
    font-size: 25px;
    font-weight: bold;
    text-align: center;
  }

  p {
    margin-top: 20px;
    font-size: 18px;
    color: $blue;
    text-align: center;
    font-weight: bold;
    .icon {
      font-size: 35px;
      color: $green;
    }
  }
}

.single-tab-modal-content {
  form {
    max-width: none !important;
    .inputs-container {
      .input-row:last-child {
        margin-bottom: 0;
      }
    }
    p {
      margin: 1em 0;
    }
    .row {
      align-items: stretch;
    }
    .link-entry {
      font-weight: bold;
      font-size: 16px;
      position: relative;
      @include media("<tablet") {
        margin-top: 5px;
        margin-left: 0;
      }
      &:after {
        content: "";
        position: absolute;
        bottom: -3px;
        left: 0;
        width: 0;
        height: 3px;
        background-color: $green;
        -webkit-transition: width 0.3s;
        transition: width 0.3s;
      }
      &:hover {
        &:after {
          width: 100%;
          @include media("<tablet") {
            width: 0;
          }
        }
      }
    }
    textarea {
      width: 100%;
      padding: 15px;
      height: 100%;
      resize: none;
      border: 1px solid $brand;
      @include media("<tablet") {
        height: 110px;
        margin: 15px 0;
      }
    }

    .error-message {
      margin-top: 15px;
      color: $red-base;
      font-size: 14px;
      font-weight: bold;
      opacity: 0;
      margin-left: 15px;
      visibility: hidden;
      transition: all 0.4s;
      &.open {
        opacity: 1;
        visibility: visible;
      }
    }
  }
}
