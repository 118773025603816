@import "../../../../../../assets/scss/vendors/include-media";
.range-date-component {
  .main-container {
    .col-xs-12 {
      @include media("<tablet") {
        margin-top: 20px;
      }
    }
  }
}
