@import "../../../assets/scss/base/variables";
@import "../../../assets/scss/vendors/include-media";
.account-page {
  height: calc(100% - 51px);

  .table-section {
    padding: 0;
  }

  .table-export {
    th {
      text-align: left;
    }
    td {
      font-weight: 500;
    }
    .redeem {
      cursor: pointer;
      color: $brand;
      white-space: nowrap;
      text-decoration: underline;
      &:hover {
        text-decoration: none;
      }
    }
  }

  .export-packages {
    padding: 25px 25px 0;
  }

  .plan-detail {
    padding: 25px 25px 0;
    h2 {
      margin: 0 0 30px;
    }
    .details-list {
      list-style: none;
      padding: 0;
      margin: 0;
      li {
        display: flex;
        .col-detail {
          padding: 0 30px 15px 0;
          > span {
            display: block;
            font-weight: 500;
            &.label {
              color: $blue;
            }
          }
          .status {
            color: $green;
          }
        }
      }
    }
  }
}

.liste.hidden {
  display: none !important;
}
.label.hidden {
  display: none !important;
}

.conso-detail {
  padding: 25px 25px 0;
  h2 {
    margin: 0 0 30px;
  }

  p {
    font-weight: 500;
  }

  span.balance,
  a.rachat {
    color: $green;
  }

  a.rachat {
    margin-left: 10px;
    text-decoration: underline;
  }
  @include media("<tablet") {
    .table-holder {
      padding: 0;
    }
  }
}

.account-sidebar {
  padding: 35px 20px 20px 25px;
  .edit-btn {
    display: inline-block;
    color: $brand;
    background: $white;
    line-height: 34px;
    padding: 0 7px;
    border-radius: 30px;
    margin: 0 0 0 10px;
    font-size: 20px;
    cursor: pointer;
    &:hover {
      background: $light-gray;
    }
  }

  .list-info {
    margin: 0;
    padding: 30px 0 0;
    list-style: none;
    li {
      padding: 0 0 15px;
      font-weight: 500;
      span {
      }
      .label {
        padding: 0 20px 0 0;
        color: $blue;
      }
    }
  }

  .modify {
    button {
      padding-top: 20px;
      border: 0;
      background: none;
      cursor: pointer;
      color: $green;
    }
  }

  .lock {
    border: 0;
    background: none;
    cursor: pointer;
    filter: invert(45%) sepia(100%) saturate(500%) hue-rotate(64deg)
      brightness(92%) contrast(86%);
    width: 8%;
  }

  .edit {
    background-color: transparent;
    color: #32a31a;
    border: none;
    margin: 0 15px;
  }

  .actions-holder {
    padding-top: 10%;
  }

  .edit-input {
    display: block;
  }
}
