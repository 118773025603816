@import "../../../assets/scss/base/variables";

.error-bar {
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  background-color: $red;
  z-index: 2000;
  opacity: 0;
  visibility: hidden;
  transition:
    visibility 0s,
    all 0.4s;
  color: #fff;
  top: 60px;
  display: flex;
  padding: 8px 15px;
  align-items: center;
  border-radius: 4px;
  padding-right: 50px;

  &.show {
    top: 80px;
    visibility: visible;
    opacity: 1;
  }

  .icon-container {
    background-color: #fff;
    width: 25px;
    height: 25px;
    border-radius: 100%;
    position: relative;
    span {
      font-size: 14px;
      position: absolute;
      top: 50%;
      color: $red;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .text-container {
    margin-left: 15px;
    font-size: 16px;
    font-weight: bold;
  }

  .close-container {
    position: absolute;
    top: 3px;
    right: 10px;
    cursor: pointer;
    span {
      font-size: 10px;
      color: #fff;
    }
  }
}
