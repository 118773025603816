.lds-ring {
  display: inline-block;
  position: relative;
  width: 50px;
  height: 50px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 40px;
  height: 40px;
  margin: 8px;
  border: 4px solid #1976d2;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #1976d2 transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.logout {
  .container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  .modal {
    background-color: rgb(255, 255, 255);
    color: rgba(0, 0, 0, 0.87);
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-radius: 4px;
    box-shadow:
      rgb(0 0 0 / 20%) 0px 2px 1px -1px,
      rgb(0 0 0 / 14%) 0px 1px 1px 0px,
      rgb(0 0 0 / 12%) 0px 1px 3px 0px;
    width: 40%;
    min-width: 400px;
    max-width: 400px;
    height: 260px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-bottom: 30px;
  }

  .modal-content {
    display: flex;
    flex-direction: column;
    height: 180px;
    align-items: center;
    justify-content: space-evenly;
    background-color: rgb(249, 250, 254);
    color: rgb(55, 68, 78);
    font-weight: 500;
    margin-bottom: 10px;
  }

  .modal-text {
    font-weight: 600;
    color: #37444e;
  }

  .loader-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
