@import "../../../assets/scss/vendors/include-media";
.header {
  background-color: #7e97c5;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  padding: 11px 30px;
  z-index: 12;
  padding-left: 75px;
  height: 62px;
  @include media(">=tablet") {
    padding: 11px 30px;
  }
  .logo {
    align-items: contain;
    &.logoManageo {
      padding-right: 30px;
      @include media("<tablet") {
        display: none;
      }
    }
  }
  .menu {
    width: 21px;
    height: 21px;
    position: relative;
    transition: 0.1s;
    margin: 10px 10px 10px 40px;
    cursor: pointer;
    display: inline-block;

    &:hover span {
      transform: scale(1.3);
      transition: 350ms cubic-bezier(0.8, 0.5, 0.2, 1.4);
      box-shadow: 0 2px 3px rgba(0, 0, 0, 0.4);
    }
  }

  .begin {
    display: flex;
    align-items: center;

    .select {
      margin-left: 30px;
      width: 300px;
      @include media("<tablet") {
        width: 215px;
      }
      > div {
        border-radius: 0;
        border: 0;
        box-shadow: 0;
        border-color: none;
        margin: 1px 0;
      }
    }
  }
}
.row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.icon-menu {
  position: relative;
  background-color: transparent;
  border: none;
  padding: 0;
  color: #fff;
  font-size: 20px;
  margin-left: 40px;
  outline: none;
  &:after {
    display: block;
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: calc(100% + 18px);
    height: calc(100% + 18px);
    border-radius: 100%;
    z-index: 0;
  }
  &:before {
    position: relative;
    z-index: 2;
  }
  &:hover,
  &.active {
    color: #37444e;
    &:after {
      background-color: #fff;
      opacity: 0.4;
    }
  }
}
.header-menu {
  position: relative;
  .prods-holder {
    right: 0;
    top: 35px;
    background-color: #37444e;
    min-height: 250px;
    min-width: 200px;
    position: absolute;
    box-shadow: 5px 0px 25px 0px rgba(0, 0, 0, 0.35);
    &.shown {
      display: block;
    }
    &.closed {
      display: none;
    }
  }
}
.toogle {
  height: 35px;
  width: 35px;
  border-radius: 50%;
  border: 0px;
  background-color: #b6c4dd;
  justify-content: center;
  align-items: center;
  @include media("<tablet") {
    display: none;
  }
}

.icon-icon-menu {
  position: relative;
  top: 5%;
}

.user-dropdown {
  display: none;
  position: absolute;
  top: 100%;
  right: 0%;
  background: #37444e;
  border-width: 0 1px 1px;
  &.active {
    display: inline-flex;
    align-items: center;
  }
  ul.apps > li {
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }
  ul {
    padding: 0;
    margin: 0;
    list-style: none;
    li {
      padding: 10px 10px;
      &:hover {
        background: gray;
      }
      .link {
        color: white;
        display: inline-block;
        width: 100%;
        font-size: 14px;
        line-height: 16px;
        font-weight: 500;
        cursor: pointer;
        .icon {
          color: white;
          margin: 0 8px 0 0;
          display: inline-block;
          vertical-align: top;
          line-height: 18px;
        }
      }
    }
  }
}

.img.mList {
  height: 6em;
}
.img.mCampaign {
  height: 6em;
}
