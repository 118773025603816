@import "../../../../../assets/scss/base/variables";
.icon-chart-section {
  padding: 10px 20px 15px 20px;
  background-color: #ffffff;
  height: 100%;
  font:
    14px "Raleway",
    "Arial",
    "Helvetica Neue",
    "Helvetica",
    sans-serif;
  h3 {
    text-align: center;
    margin-bottom: 10px;
    color: $blue;
    font:
      14px "Raleway",
      "Arial",
      "Helvetica Neue",
      "Helvetica",
      sans-serif;
    font-weight: 600;
  }

  .icons-holder {
    height: 90%;
    text-align: center;
    flex-flow: row wrap;
    display: flex;
    justify-content: center;
    align-items: center;

    .icon-holder {
      width: 49%;
      display: inline-block;

      .icon {
        padding-top: 5px;
        color: #4a63a1;
        font-size: 45px;
      }

      .potential {
        padding-top: 5px;
        font:
          14px "Raleway",
          "Semibold";
        color: #71cc51;
        font-weight: bold;
        font-variant-numeric: lining-nums;
      }

      .title {
        padding-top: 5px;
        font:
          14px "Raleway",
          "Semibold italic",
          sans-serif;
      }
    }
  }
}
