.modal-select {
  margin-bottom: 15px;

  .refresh-btn {
    transition: all 0.2s;
    opacity: 1;
    visibility: visible;
    &.disabled {
      opacity: 0;
      visibility: hidden;
    }
  }
}
