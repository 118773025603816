@import "./vendors/include-media";
html,
body,
#root {
  height: 100%;
}

::-webkit-scrollbar {
  -webkit-overflow-scrolling: auto;
  width: 8px !important;
}
::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.1) !important;
  border-radius: 4px !important;
}
::-webkit-scrollbar-thumb {
  background: #32a31a !important;
  border-radius: 4px !important;
}
::-webkit-scrollbar-thumb:hover {
  cursor: pointer !important;
}
.scrollAuto {
  overflow: auto;
  height: inherit;
}
#wrapper {
  overflow: hidden;
}

.main-content {
  width: 100%;
  .board-content {
    padding-top: 62px;
  }
}

.btn {
  @include animate(opacity visibility color background box-shadow);
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-weight: 700;
  color: $white;
  padding: 15px 30px;
  border: none;

  &:hover,
  &.hover {
    opacity: 0.8;
    box-shadow:
      0px 0px 22px 12px rgba(0, 0, 0, 0.2),
      inset 0px 0px 22px 12px rgba(0, 0, 0, 0.3);
  }

  &:focus,
  &.focus {
    box-shadow:
      0px 0px 22px 6px rgba(0, 0, 0, 0.15),
      inset 0px 0px 22px 12px rgba(0, 0, 0, 0.3);
  }

  &.disabled {
    background: linear-gradient(to bottom, #646464 0%, #9b9b9b 100%);
    background-color: #818181;
    box-shadow: inset 0px 0px 22px 12px rgba(0, 0, 0, 0.3);
    opacity: 0.4;
    pointer-events: none;
  }

  &.btn-primary {
    background-image: linear-gradient(
      to bottom,
      #75e34e,
      #6dd348,
      #64c442,
      #5cb53d,
      #54a637
    );
    box-shadow: 0px 12px 22px 0px rgba(0, 0, 0, 0.3);
  }

  &.btn-secondary {
    background: linear-gradient(
      to bottom,
      rgba(74, 99, 161, 1) 0%,
      rgba(74, 99, 161, 0) 100%
    );
    background-color: #91a8d0;
  }

  &.btn-warning {
    background-image: linear-gradient(
      to bottom,
      #f9ab38,
      #f89e2d,
      #f69122,
      #f58318,
      #f37510
    );

    .icon {
      margin-left: 20px;
    }
  }
}

.board-content {
  .row {
    overflow-x: hidden;
    overflow-y: scroll;
    &.ovv {
      overflow: auto;
    }
  }
}
.text-left {
  text-align: left;
}
.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
  @include media("<tablet") {
    text-align: left;
  }
}
.with-shadow {
  box-shadow: 5px 0px 25px -6px rgba(0, 0, 0, 0.35);
  @include media("<tablet") {
    box-shadow: none;
  }
}
.h-100 {
  height: 100%;
}

@include media("<tablet") {
  .h-100 {
    height: auto;
  }
}
.por {
  position: relative;
}
.d-flex {
  display: flex;
  justify-content: space-between;
}

.App {
  overflow: hidden;
}

.scrollin {
  overflow-y: auto;
}

.pb-5 {
  padding-bottom: 60px;
}

hr {
  border: none;
  border-bottom: 1px solid $light-blue;
}
.mb50 {
  margin-bottom: 50px;
}
.mb60 {
  margin-bottom: 60px;
}

.text-holder {
  margin-bottom: 25px;
  line-height: 1.45;
  h2 {
    max-width: 250px;
    letter-spacing: 0.5px;
  }
}

.route-wrapper {
  height: 100%;
  position: relative;
}

.route-wrapper > div {
  position: absolute;
  height: 100%;
  width: 100%;
}

.container-fluid {
  padding: 0 !important;
}

.pb-10 {
  padding-bottom: 125px;
  @include media("<tablet") {
    padding-bottom: 10px;
  }
}

.with-bg {
  background-color: #ffffff;
}

// Steps loader
#loadercontainer {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  &.active {
    display: block;
  }
}
