@import "../../../../../assets/scss/base/variables";
@import "../../../../../assets/scss/vendors/include-media";

.export-credits-section {
  padding: 40px 30px;
  @include media("<tablet") {
    padding: 20px 30px;
  }
  .row {
    overflow: visible;
  }
  .btn-modal {
    margin-top: 18px;
    margin-left: 10px;
    margin-right: 10px;
    width: 35%;
  }
  .inner_div {
    text-align: center !important;
  }
}
.export-format-section {
  padding: 40px 30px;
  @include media("<tablet") {
    padding: 0 30px 90px;
  }
  h1 {
    color: $blue;
    @include media("<tablet") {
      font-size: 20px;
    }
  }
}
.export-format-holder {
  padding: 20px 0;
  margin: 0 -15px;
  display: flex;
  justify-content: center;
  @include media("<tablet") {
    flex-wrap: wrap;
    flex-direction: column;
  }
  .export-format-col {
    padding: 0 15px;
    width: 120px;
    text-align: center;
    @include media("<tablet") {
      margin: auto;
      margin-bottom: 15px;
    }
  }
}
.export-campaign-message {
  text-align: center;
  margin: auto;
  @include media("<tablet") {
    width: 100%;
  }
  .campaign-message {
    font-size: 15px;
    @include media("<tablet") {
      font-size: 12px;
    }
    .icon {
      padding: 0 5px 0 0;
      vertical-align: middle;
    }
  }
}
.export-format {
  color: $light-brand;
  .icon-block {
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.3);
    height: 90px;
    position: relative;
    margin-bottom: 25px;
    cursor: pointer;
    background-color: $white;
    @include media("<tablet") {
      margin-bottom: 10px;
    }
  }
  .icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 60px;
    font-weight: 300;
  }
  .export-format-label {
    color: $blue;
    font-weight: 600;
  }
  &.selected {
    color: $white;
    .icon-block {
      background-color: $light-brand;
    }
    .export-format-label {
      color: $light-brand;
    }
  }
  &.unselectable {
    color: #cccccc;
    .icon-block {
      cursor: not-allowed;
      background-color: $white;
    }
    .export-format-label {
      color: #cccccc;
    }
  }
}

.export-credits-content {
  .row {
    align-items: stretch;
  }

  .col-xs-12 {
    @include media("<tablet") {
      margin-bottom: 10px;
    }
  }

  .card-holder {
    height: 100%;
    background: $white;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.3);
    padding: 20px;
    text-align: center;

    .btn-modal {
      margin-top: 15px;
      width: 60%;
    }

    .card-header {
      .icon-holder {
        display: inline-block;
        border: 2px solid $blue;
        width: 50px;
        height: 50px;
        border-radius: 50px;
        font-size: 23px;
        line-height: 44px;
        color: $blue;
        margin: 0 0 10px;
      }
      .card-title {
        font-size: 16px;
        line-height: 21px;
        min-height: 42px;
      }
      .card-title-number {
        color: $green;
        font-size: 38px;
        font-weight: 600;
        line-height: 42px;
        margin: 0 0 20px;
      }
    }

    .card-content {
      .card-content-title {
        font-size: 14px;
        line-height: 16px;
        color: $blue;
      }
      .card-content-title-number {
        font-weight: 600;
        color: $green;
        font-size: 25px;
        line-height: 27px;
        margin: 0 0 16px;
      }
    }
  }

  .card-hint {
    padding: 0 10px;
    p {
      margin: 0;
      font-size: 12px;
      line-height: 15px;
      color: $blue;
      .icon {
        font-size: 15px;
        padding: 0 5px 0 0;
        vertical-align: middle;
      }
    }
    &.danger {
      color: $red;
      span {
        color: $black;
      }
    }
  }
  .red {
    color: $red !important;
  }
}
