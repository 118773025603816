@import "../../../assets/scss/vendors/include-media";
@import "../../../assets/scss/base/variables";

.menu {
  z-index: 11;
  position: fixed;

  @include media(">=tablet") {
    transform: none;
    position: static;
  }

  .menu-holder {
    width: 75px;
    transition: width 1s;
    display: inline-block;
    min-height: 100vh;
    background-color: $dark-blue;
    display: flex;
    height: 100%;
    flex-direction: column;
    padding-top: 57px;
    transition: all 0.5s;
    justify-content: space-between;

    @include media(">=tablet") {
      box-shadow: 5px 0px 25px 0px rgba(0, 0, 0, 0.35);
      width: auto;
    }

    &.noExpanded {
      @include media(">=tablet") {
        .link-title {
          width: 0;
          visibility: hidden;
          font-size: 0;
        }
        .icon {
          margin: 0;
        }
      }
      @include media("<tablet") {
        width: auto;
      }
      @include media(">=tablet") {
        .hasSubMenu {
          .link {
            &.active {
              background-color: #313c45;

              &:after {
                transform: rotate(0deg);
              }
            }
          }

          .subMenu {
            display: none;
            position: absolute;
            top: -1px;
            left: 100%;
            box-shadow: 5px 0px 25px 0px rgba(0, 0, 0, 0.35);

            &.opened {
              display: block;
            }

            ul {
              li {
                a {
                  white-space: nowrap;
                  padding: 11px 15px 11px 15px;
                }
              }
            }
          }
        }
      }

      @include media("<tablet") {
        .hasSubMenu {
          .subMenu {
            display: none;

            &.opened {
              display: block;
            }

            ul {
              li {
                a {
                  padding: 11px 15px 11px 4vh;
                }
              }
            }
          }
        }
      }
    }

    &.expanded {
      width: 100vw;

      @include media(">=tablet") {
        width: auto;
      }

      .link-title {
        width: 110px;
        visibility: visible;
      }

      .expand {
        justify-content: flex-end;

        &:before {
          transform: rotate(180deg);
        }
      }

      .hasSubMenu {
        .subMenu {
          display: none;

          &.opened {
            display: block;
          }

          ul {
            li {
              a {
                padding: 11px 15px 11px 4vh;
              }
            }
          }
        }
      }
    }

    .link-title {
      white-space: nowrap;
      transition:
        width 0.3s,
        visibility 0.3s;
    }
  }

  .list {
    margin: 0;
    padding: 0;
    list-style: none;

    .item {
      text-align: left;
      border-bottom: 1px solid #293038;
    }

    .link {
      font-size: 14px;
      color: #fff;
      text-decoration: none;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 25px 35px;
      padding: 3vh 5vh;

      &:hover {
        background-color: #313c45;
      }
      @include media("<tablet") {
        justify-content: flex-start;
      }
    }
  }

  .list-down {
    position: absolute;
    bottom: 0;
    list-style: none;
    border-top: 1px solid #293038;
  }

  .link {
    &.active span.icon {
      position: relative;
      color: $light-brand;

      &:after {
        display: block;
        content: "";
        width: calc(100% + 10px);
        height: calc(100% + 10px);
        background-color: $light-brand;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        opacity: 0.1;
        border-radius: 100%;
      }
    }
  }

  .icon {
    font-size: 18px;
    margin-right: 15px;

    @include media(">=tablet") {
      font-size: 20px;
    }
  }
}

.hasSubMenu {
  position: relative;

  .link {
    position: relative;

    &:after {
      position: absolute;
      right: 7px;
      top: 50%;
      margin-top: -3px;
      content: "";
      width: 0;
      height: 0;
      border-top: 4px solid transparent;
      border-left: 5px solid #fff;
      border-bottom: 4px solid transparent;
      transform: rotate(0);
    }

    &.active {
      background-color: #313c45;

      &:after {
        transform: rotate(90deg);
      }
    }
  }

  .subMenu {
    background-color: #37444e;
    display: none;
    position: relative;

    &.opened {
      display: block;
    }

    ul {
      list-style: none;
      padding: 0;

      li {
        a {
          display: block;
          color: #fff;

          &:hover {
            background-color: #313c45;
          }
        }
      }
    }
  }
}
.accountSubMenu {
  display: none;
  @include media("<tablet") {
    display: list-item;
  }
  .active,
  .icon,
  .subMenu a {
    font-size: 14px;
  }
}

.expand {
  display: flex;
  background-color: transparent;
  border: none;
  justify-content: center;
  align-items: center;
  font-size: 5vh;
  outline: none;
  padding: 20px 24px;
  color: #fff;

  &:before {
    transition: transform 0.3s;
    transform: rotate(0);
  }
  @include media("<tablet") {
    display: none;
  }
}
