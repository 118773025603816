@font-face {
  font-family: "icomoon";
  src: url("../../fonts/icomoon.eot?r9y20r");
  src:
    url("../../fonts/icomoon.eot?r9y20r#iefix") format("embedded-opentype"),
    url("../../fonts/icomoon.ttf?r9y20r") format("truetype"),
    url("../../fonts/icomoon.woff?r9y20r") format("woff"),
    url("../../fonts/icomoon.svg?r9y20r#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-social-network:before {
  content: "\e900";
}
.icon-full-2:before {
  content: "\e901";
}
.icon-full:before {
  content: "\e902";
}
.icon-close-lg:before {
  content: "\e903";
}
.icon-changes:before {
  content: "\e904";
}
.icon-power:before {
  content: "\e905";
}
.icon-settings:before {
  content: "\e906";
}
.icon-plus:before {
  content: "\e907";
}
.icon-close:before {
  content: "\e908";
}
.icon-edit-3:before {
  content: "\e909";
}
.icon-myLists:before {
  content: "\e90a";
}
.icon-personalisation:before {
  content: "\e90b";
}
.icon-minus:before {
  content: "\e90c";
}
.icon-icon-menu:before {
  content: "\e90d";
}
.icon-dashbord:before {
  content: "\e90e";
}
.icon-createList:before {
  content: "\e90f";
}
.icon-support:before {
  content: "\e910";
}
.icon-propos:before {
  content: "\e911";
}
.icon-step-ciblage:before {
  content: "\e912";
}
.icon-step-parametrage:before {
  content: "\e913";
}
.icon-step-exportation:before {
  content: "\e914";
}
.icon-step-confirmation:before {
  content: "\e915";
}
.icon-support-call:before {
  content: "\e916";
}
.icon-on-map:before {
  content: "\e917";
}
.icon-float-text:before {
  content: "\e918";
}
.icon-edit-2:before {
  content: "\e919";
}
.icon-full-3:before {
  content: "\e91a";
}
.icon-plus-sm:before {
  content: "\e91b";
}
.icon-chevron-down:before {
  content: "\e91c";
}
.icon-edit:before {
  content: "\e91d";
}
.icon-arrow-thin-right:before {
  content: "\e91e";
}
.icon-refresh:before {
  content: "\e91f";
}
.icon-on-load:before {
  content: "\e920";
}
.icon-graph:before {
  content: "\e921";
}
.icon-remove:before {
  content: "\e922";
}
.icon-xls:before {
  content: "\e923";
}
.icon-csv:before {
  content: "\e924";
}
.icon-view:before {
  content: "\e925";
}
.icon-dont-view:before {
  content: "\e926";
}
.icon-send:before {
  content: "\e927";
}
.icon-search:before {
  content: "\e928";
}
.icon-calendar:before {
  content: "\e929";
}
.icon-letter:before {
  content: "\e92a";
}
.icon-phone:before {
  content: "\e92b";
}
.icon-money:before {
  content: "\e92c";
}
.icon-phone-2:before {
  content: "\e92d";
}
.icon-car:before {
  content: "\e92e";
}
.icon-dons-group:before {
  content: "\e92f";
}
.icon-leadership:before {
  content: "\e930";
}
.icon-office:before {
  content: "\e931";
}
.icon-e-mail:before {
  content: "\e932";
}
.icon-law:before {
  content: "\e933";
}
.icon-mobile:before {
  content: "\e934";
}
.icon-mailer:before {
  content: "\e935";
}
.icon-global:before {
  content: "\e936";
}
.icon-search-all:before {
  content: "\e937";
}
.icon-copyright:before {
  content: "\e938";
}
.icon-pc-all:before {
  content: "\e939";
}
.icon-tower-block-front-:before {
  content: "\e93a";
}
.icon-leadership-2:before {
  content: "\e93b";
}
.icon-mobile-light:before {
  content: "\e93c";
}
.icon-tower-block-front-light:before {
  content: "\e93d";
}
.icon-leadership-3:before {
  content: "\e93e";
}
.icon-card:before {
  content: "\e93f";
}
.icon-cars:before {
  content: "\e940";
}
.icon-car-light:before {
  content: "\e941";
}
.icon-chain:before {
  content: "\e942";
}
.icon-shop:before {
  content: "\e943";
}
.icon-dons-group-light:before {
  content: "\e944";
}
.icon-leadership-light:before {
  content: "\e945";
}
.icon-mailer-light:before {
  content: "\e946";
}
.icon-e-mail-light:before {
  content: "\e947";
}
.icon-arrow-right:before {
  content: "\e948";
}
.icon-building:before {
  content: "\e949";
}
.icon-phone-2-light:before {
  content: "\e94a";
}
.icon-global-light:before {
  content: "\e94b";
}
.icon-search-all-light:before {
  content: "\e94c";
}
.icon-pc-all-light:before {
  content: "\e94d";
}
.icon-copyright-light:before {
  content: "\e94e";
}
.icon-user:before {
  content: "\e94f";
}
.icon-twitter:before {
  content: "\e950";
}
.icon-google-plus:before {
  content: "\e951";
}
.icon-linkedin:before {
  content: "\e952";
}
.icon-youtube:before {
  content: "\e953";
}
.icon-facebook:before {
  content: "\e954";
}
.icon-confirm:before {
  content: "\e955";
}
.icon-blank:before {
  content: "\e956";
}
.icon-messages:before {
  content: "\e957";
}
.icon-doc:before {
  content: "\e958";
}
.icon-aim:before {
  content: "\e959";
}
.icon-value:before {
  content: "\e95a";
}
.icon-mail-to:before {
  content: "\e95b";
}
.icon-building-2:before {
  content: "\e95c";
}
.icon-gap:before {
  content: "\e95d";
}
.icon-excel-doc:before {
  content: "\e95e";
}
.icon-csv-doc:before {
  content: "\e95f";
}
.icon-crm-doc:before {
  content: "\e960";
}
.icon-mail-to-2:before {
  content: "\e961";
}
.icon-uniE962:before {
  content: "\e962";
}
.icon-uniE963:before {
  content: "\e963";
}
.icon-uniE964:before {
  content: "\e964";
}
.icon-uniE965:before {
  content: "\e965";
}
.icon-uniE966:before {
  content: "\e966";
}
.icon-uniE967:before {
  content: "\e967";
}
.icon-uniE968:before {
  content: "\e968";
}
.icon-uniE969:before {
  content: "\e969";
}
.icon-uniE96A:before {
  content: "\e96a";
}
.icon-uniE96B:before {
  content: "\e96b";
}
.icon-uniE96C:before {
  content: "\e96c";
}
.icon-uniE96D:before {
  content: "\e96d";
}
.icon-uniE96E:before {
  content: "\e96e";
}
.icon-uniE96F:before {
  content: "\e96f";
}
.icon-uniE970:before {
  content: "\e970";
}
.icon-uniE971:before {
  content: "\e971";
}
.icon-uniE972:before {
  content: "\e972";
}
.icon-uniE973:before {
  content: "\e973";
}
.icon-uniE974:before {
  content: "\e974";
}
.icon-uniE975:before {
  content: "\e975";
}
.icon-uniE976:before {
  content: "\e976";
}
.icon-uniE977:before {
  content: "\e977";
}
.icon-uniE978:before {
  content: "\e978";
}
.icon-uniE979:before {
  content: "\e979";
}
.icon-uniE97A:before {
  content: "\e97a";
}
.icon-uniE97B:before {
  content: "\e97b";
}
.icon-uniE97C:before {
  content: "\e97c";
}
.icon-uniE97D:before {
  content: "\e97d";
}
.icon-uniE97E:before {
  content: "\e97e";
}
.icon-uniE97F:before {
  content: "\e97f";
}
.icon-uniE980:before {
  content: "\e980";
}
.icon-uniE981:before {
  content: "\e981";
}
.icon-uniE982:before {
  content: "\e982";
}
.icon-uniE983:before {
  content: "\e983";
}
.icon-uniE984:before {
  content: "\e984";
}
.icon-uniE985:before {
  content: "\e985";
}
.icon-uniE986:before {
  content: "\e986";
}
.icon-uniE987:before {
  content: "\e987";
}
.icon-uniE988:before {
  content: "\e988";
}
.icon-uniE989:before {
  content: "\e989";
}
.icon-uniE98A:before {
  content: "\e98a";
}
.icon-uniE98B:before {
  content: "\e98b";
}
.icon-uniE98C:before {
  content: "\e98c";
}
.icon-uniE98D:before {
  content: "\e98d";
}
.icon-angle-left:before {
  content: "\e98e";
}
.icon-angle-double-left:before {
  content: "\e98f";
}
.icon-angle-right:before {
  content: "\e990";
}
.icon-angle-double-right:before {
  content: "\e991";
}
.icon-map-pin:before {
  content: "\e992";
}
.icon-padlock:before {
  content: "\e993";
}
.icon-circle:before {
  content: "\e994";
}
.icon-polygon:before {
  content: "\e995";
}
.icon-camera:before {
  content: "\e996";
}
.icon-etablissement:before {
  content: "\e997";
}
.icon-siege:before {
  content: "\e998";
}
.icon-siege-et-etab:before {
  content: "\e999";
}
.icon-scoring:before {
  content: "\e99a";
}
.icon-actionnaire:before {
  content: "\e99b";
  color: #142f65;
}
.icon-filiale:before {
  content: "\e99c";
  color: #142f65;
}
.icon-dir-principal:before {
  content: "\e99d";
  color: #142f65;
}
.icon-dir-fonctionnel:before {
  content: "\e99e";
  color: #142f65;
}
