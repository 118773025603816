@import "../../../../../assets/scss/vendors/include-media";
@import "../../../../../assets/scss/base/variables";

.filter-section {
  overflow: auto;
  height: inherit;
  background-color: #f1f3fc;
}
.header-section {
  padding: 25px 25px 0;
  margin-bottom: 23px;
}

.tab-heading {
  padding: 0 10px;
  padding-right: 45px;
  position: relative;
  display: flex;
  border-bottom: 1px solid $light-blue;
  &.end {
    justify-content: flex-end;
  }
  button {
    outline: none;
    cursor: pointer;
    text-transform: uppercase;
    padding: 10px 10px 15px;
    display: block;
    font-weight: 700;
    font-size: 12px;
    position: relative;
    border: none;
    color: $light-blue;
    background: transparent;
    @include media(">=tablet") {
      padding: 15px 15px 20px;
      font-size: 14px;
    }
    &:after {
      transition: width 0.3s;
      position: absolute;
      content: "";
      width: 0;
      height: 3px;
      background-color: $green;
      bottom: 0;
      left: 0;
      right: 0;
    }
    &:hover {
      color: $green;
      &:after {
        width: 100%;
      }
    }
    &.tab-link-active {
      color: $green;
      &:after {
        width: 100%;
      }
    }
  }
  .icon {
    margin-right: 12px;
    font-size: 24px;
  }
  &.capitalize-text {
    button {
      font-size: 16px;
      text-transform: capitalize;
    }
  }
}
.mes-filters {
  .Collapsible {
    padding: 0;
  }
  .Collapsible__trigger {
    border-bottom: none;
    padding: 17px 53px 17px 26px;
    &.is-open {
      background: $white;
    }
    &:after {
      right: 20px;
    }
  }
  .Collapsible__contentOuter {
    padding: 0 25px;
    border-bottom: 1px solid $light-blue;
    background: $white;
  }
  .Collapsible__contentInner {
    padding: 0 0 20px;
  }
}

.Collapsible {
  padding: 0 10px;
  position: relative;
  @include media(">=widescreen") {
    padding: 0 25px;
  }
}
.Collapsible__contentInner {
  padding: 15px 0;
}
.Collapsible__trigger {
  position: relative;
  cursor: pointer;
  outline: none;
  display: block;
  font-weight: 700;
  color: $blue;
  border-bottom: 1px solid $light-blue;
  padding: 15px 40px 15px 15px;
  font-size: 16px;
  &:after {
    position: absolute;
    content: "\e91c";
    color: $green;
    font-family: "icomoon" !important;
    font-weight: normal;
    transform: rotate(180deg);
    transition: transform 0.3s;
    top: 10px;
    right: 5px;
    font-size: 24px;
  }
  &.is-closed {
    &:after {
      transform: rotate(0deg);
    }
    .modal-opener {
      color: $dark-blue;
    }
  }
}
.Collapsible__contentOuter {
  padding: 0 10px;
}

.modal-opener {
  background-color: transparent;
  padding: 0;
  border: none;
  outline: none;
  font-size: 12px;
  color: $brand;
}
.title-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .title {
    display: flex;
    align-items: center;
    width: 100%;
    .icon {
      margin-right: 10px;
    }
  }
  &.with-subtitle {
    display: block;
  }
}

.react-tabs {
  margin-bottom: 20px;
}

.Collapsible__contentInner {
  font-size: 14px;
  @include media(">=widescreen") {
    font-size: 16px;
  }
}

.btn-wrapper {
  padding: 10px 0;
  display: flex;
  flex-wrap: wrap;
}
.add-new-data {
  padding: 0 25px;
  h4 {
    color: $blue;
    font-size: 18px;
  }
  h5 {
    color: $dark-blue;
    font-size: 14px;
    margin-bottom: 15px;
  }
}

.Collapsible {
  .icon {
    font-size: 20px;
    @include media(">=desktop") {
      font-size: 24px;
    }
  }
}

.tabs {
  margin-bottom: 20px;
}
.selection {
  display: block;
  margin: 0;
  margin-right: 0;
  margin-left: auto;
  font-size: 10px;
  font-weight: 500;
  color: $dark-blue;
  font-style: italic;
  @include media(">=desktop") {
    font-size: 12px;
  }
  &.on {
    color: $green;
    font-weight: 600;
  }
}

.extra-actions {
  position: absolute;
  right: 15px;
  top: 0;
  bottom: 0;
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-self: end;
  li {
    .icon {
      color: $green;
      cursor: pointer;
      transition: opacity 0.1s;
      &:hover {
        opacity: 0.5;
      }
    }
    &:last-child {
      .icon {
        margin-right: 0;
      }
    }
  }
}
.check-wrapper {
  .subtitle {
    color: $black;
    display: block;
    font-weight: 400;
    font-size: 14px;
    padding: 0 25px 10px;
    margin: 0 -25px 20px;
    box-shadow: 0px 15px 24px -19px rgba(0, 0, 0, 0.75);
    @include media("<tablet") {
      box-shadow: none;
    }
  }
  ul {
    list-style: none;
    padding: 10px 0;
    margin: 0;
    li {
      label {
        font-size: 14px;
        cursor: pointer;
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
        align-items: center;
        .custom-checkbox {
          margin-right: 0;
          margin-left: 5px;
        }
      }
      margin-bottom: 20px;
    }
  }
}
.check-wrapper {
  ul {
    list-style: none;
    padding: 10px 0;
    margin: 0;
    li {
      label {
        cursor: pointer;
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
        align-items: center;
        .custom-checkbox {
          margin-right: 0;
          margin-left: 5px;
        }
      }
      margin-bottom: 20px;
    }
  }
}

.my-filters {
  li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .fake-link {
      text-decoration: underline;
      font-weight: 700;
      font-size: 14px;
      cursor: pointer;
      color: $green;
      &:hover {
        opacity: 0.75;
      }
    }
  }
}

.prefilter-slider {
  > div {
    cursor: pointer;
    > div:last-child {
      transform: translate(-50%, -4px);
      &:after {
        top: 0;
        left: 0;
      }
    }
  }
}
.prefilter-slider {
  .rangeslider-horizontal {
    width: 45px;
    height: 12px;
    background: $brand;
    border-radius: 6px;
    position: relative;
    .rangeslider__fill {
      background: $red;
      height: 12px;
      border-radius: 6px 0 0 6px;
    }
    .rangeslider__labels {
      display: none;
    }
    .rangeslider__handle {
      height: 19px;
      width: 19px;
      background: rgb(255, 255, 255);
      display: inline-block;
      cursor: pointer;
      border-radius: 50%;
      position: absolute;
      outline: 0px;
      box-shadow: rgb(221, 226, 235) 0px 0px 2px 3px;
      border: 0px;
      .rangeslider__handle-tooltip,
      .rangeslider__handle-label {
        display: none;
      }
      &:after {
        display: none;
      }
    }
  }
  &.red {
    .rangeslider-horizontal {
      background: $red;
    }
  }
  &.green {
    .rangeslider__fill {
      background: $brand;
    }
  }
}
