@import "../../../assets/scss/vendors/include-media";
@import "../../../assets/scss/base/variables";

.page-heading {
  padding: 25px 25px 0;
  @include media("<tablet") {
    .display-btn {
      display: none;
    }
  }
}

.table-targeting {
  td .icon {
    color: $green;
    &.red {
      color: $red-base;
      font-size: 20px;
      display: inline-block;
      margin: 0 0 0 15px;
      vertical-align: middle;
    }
    &.download-btn {
      cursor: pointer;
    }
  }

  .table-holder .table-wrapper table tbody td.name {
    width: 33%;
  }
  .table-holder .table-wrapper table tbody td.desc {
    width: 33%;
  }
}

.my-filters-holder {
  .title {
    font-size: 14px;
  }
}

.family-item-option {
  margin: 0 5px;
  text-align: center;
  font-size: 15px;
  font-weight: bold;

  input {
    width: 50px;
    text-align: center;
    box-sizing: border-box;
    margin: 0 7px;
    height: 35px;
    border-radius: 5px;
    border: 1px solid gray;
  }
}
