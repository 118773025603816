@import "../../../../../assets/scss/vendors/include-media";
@import "../../../../../assets/scss/base/variables";

.search-filter {
  position: relative;
  max-width: 340px;
  @include media(">=tablet") {
    max-width: 290px;
  }
  .search-input {
    display: block;
    width: 100%;
    position: relative;
    padding: 0 40px 0 0;
    input {
      outline: none;
      padding: 10px;
      width: 100%;
      border: 1px solid $light-blue;
    }
    &:before {
      display: block;
      position: absolute;
      right: 0;
      top: 0;
      height: 100%;
      width: 40px;
      text-align: center;
      content: "\e928";
      line-height: 40px;
      font-size: 20px;
      color: $light-brand;
      background-color: $dark-blue;
      font-family: "icomoon" !important;
      speak: none;
      font-style: normal;
      font-weight: normal;
      font-feature-settings: normal;
      font-variant: normal;
      text-transform: none;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }
  }
}
