@import "../../../../../assets/scss/base/variables";
.custom-legend {
  height: 32px;
  padding: 5px 0;
  .legend {
    text-align: center;
    span {
      display: inline-block;
      margin: 0 10px 0 0;
      height: 12px;
      width: 30px;
      vertical-align: middle;
      border-radius: 5px;
    }
  }
}

.with-bg {
  height: 100%;
}

.no-slices {
  text-align: center;
  padding: 10px 20px;
  .doughnut-title {
    font-weight: bold;
    font-size: 14px;
    color: $blue;
    &.mb-10 {
      margin-bottom: 10px;
    }
  }
}
