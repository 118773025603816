.actions-holder {
  @media (max-width: 400px) {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .btn-primary {
    @media (max-width: 400px) {
      margin: 10px 0;
    }
  }
  .btn-secondary {
    margin: 0 0 0 30px;
    @media (max-width: 400px) {
      margin: 10px 0;
    }
  }
}
