.modal-two-choices {
  .checkboxes-list {
    display: flex;
    align-items: center;
    li {
      margin-right: 15px;
      &:last-child {
        margin-right: 0;
      }
    }
  }
}
