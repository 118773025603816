@import "../../../assets/scss/vendors/include-media";
@import "../../../assets/scss/base/variables";

.menu-bar {
  display: flex;
  margin: 0;
  padding: 0 45px;
  list-style: none;
  a {
    display: flex;
    align-items: center;
    height: 100%;
    padding: 0 20px;
    font-weight: 700;
    font-size: 14px;
    position: relative;
    &:after {
      transition: width 0.3s;
      position: absolute;
      content: "";
      width: 0;
      height: 3px;
      background-color: #32a31a;
      bottom: 0;
      left: 0;
      right: 0;
    }
    &:hover {
      &:after {
        width: 100%;
      }
    }
  }
}

.info-holder {
  display: flex;
}

.support-link {
  border-left: 1px solid #cbd5e7;
  border-right: 1px solid #cbd5e7;
  display: flex;
  align-items: center;
  padding: 13px 10px;
  @include media(">=desktop") {
    padding: 13px 35px;
  }
  span {
    font-size: 24px;
  }
}

.user-box {
  display: flex;
  flex-shrink: 0;
  justify-content: space-between;
  align-items: center;
  position: relative;
  .username {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px 0 10px;
    cursor: pointer;
    @include media(">=desktop") {
      padding: 0 50px;
    }
  }
  span {
    font-weight: 600;
    font-size: 14px;
    color: $green;
  }
  .img-holder {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    overflow: hidden;
    margin-left: 15px;
    max-width: 30px;
    @include media(">=desktop") {
      margin-left: 25px;
    }
  }
  .user-dropdown {
    display: none;
    position: absolute;
    top: 100%;
    background: $white;
    z-index: 1000;
    border: solid $light-blue;
    border-width: 0 1px 1px;
    left: -1px;
    &.active {
      display: block;
    }
    ul {
      padding: 0;
      margin: 0;
      list-style: none;
      li {
        border-top: 1px solid $light-blue;
        &:hover {
          background: $light-gray;
        }
        .link {
          color: $black;
          padding: 10px 20px;
          display: inline-block;
          width: 100%;
          font-size: 14px;
          line-height: 16px;
          font-weight: 500;
          cursor: pointer;
          .icon {
            color: $green;
            margin: 0 8px 0 0;
            display: inline-block;
            vertical-align: top;
            line-height: 18px;
          }
        }
      }
    }
  }
}
