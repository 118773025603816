@import "../../../assets/scss/vendors/include-media";
@import "../../../assets/scss/base/variables";
.dashboard-view {
  position: relative;

  .dashboard-cta-container {
    width: 100%;
    margin-top: 25px;
    text-align: center;
  }

  .PageHeader {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 4;
    @include media("<tablet") {
      display: none;
    }
  }

  .primary-panel {
    overflow: auto;
    position: relative;
    padding-top: 40px;
    @include media("<tablet") {
      padding-top: 0;
    }
  }
  .with-shadow.primary-panel {
    @media (max-width: "991px") {
      box-shadow: none;
    }
  }

  .no-scroll {
    @media (max-width: "991px") {
      overflow: inherit !important;
    }
  }

  .secondary-panel {
    overflow: auto;
    padding: 25px 25px 0;
    padding-top: 40px;
    @include media("<tablet") {
      padding: 0 35px;
    }
    .panel-blocks-container {
      padding-top: 25px;
    }

    .panel-block {
      margin-bottom: 50px;
      padding-right: 35px;
      @include media("<tablet") {
        margin-bottom: 20px;
        padding-right: 0;
      }
      .title-links-container {
        margin-bottom: 15px;
        display: flex;
        align-items: baseline;
        @media (max-width: "1165px") {
          flex-wrap: wrap;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;
        }
        .link-entry {
          margin-left: 25px;
          font-weight: bold;
          font-size: 16px;
          position: relative;
          @include media("<tablet") {
            margin-top: 5px;
            margin-left: 0;
          }
          &:after {
            content: "";
            position: absolute;
            bottom: -3px;
            left: 0;
            width: 0;
            height: 3px;
            background-color: $green;
            -webkit-transition: width 0.3s;
            transition: width 0.3s;
          }
          &:hover {
            &:after {
              width: 100%;
              @include media("<tablet") {
                width: 0;
              }
            }
          }
        }
      }

      > .row {
        margin-left: 0;
        margin-right: 0;
        align-items: stretch;
        overflow: inherit;
      }

      .card-item {
        @include media("<tablet") {
          padding: 0;
          margin-bottom: 15px;
        }
        .chart-content {
          text-align: center;
          padding: 10px 20px;
          background-color: #fff;
          position: relative;
          height: 100%;
          display: flex;
          justify-content: center;
          min-height: 150px;

          .chart-title {
            font-weight: bold;
            font-size: 14px;
            color: $blue;
            &.mb-10 {
              margin-bottom: 10px;
            }
          }
          .chart-value {
            font-size: 66px;
            line-height: 66px;
            color: $green;
            margin-top: 10px;
            position: absolute;
            top: 45%;
            transform: translateY(-50%);
            left: 0;
            width: 100%;
          }
        }
      }

      .table-holder {
        padding: 0;
      }

      .table-section {
        padding-bottom: 0px;
      }
    }
  }
}
