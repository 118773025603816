@import "../../../assets/scss/vendors/include-media";
@import "../../../assets/scss/base/variables";

$delayRange: 0.32s;
// 9
.sk-cube-grid {
  $delayRange: 0.4s;

  width: 64px;
  height: 64px;
  margin: auto;

  .sk-cube {
    width: 33%;
    height: 33%;
    background-color: $brand;
    float: left;
    animation: sk-cube-grid-scale-delay 1.3s infinite ease-in-out;
  }

  /*
   * Spinner positions
   * 1 2 3
   * 4 5 6
   * 7 8 9
   */

  .sk-cube-1 {
    animation-delay: ($delayRange * 0.5);
  }
  .sk-cube-2 {
    animation-delay: ($delayRange * 0.75);
  }
  .sk-cube-3 {
    animation-delay: $delayRange;
  }
  .sk-cube-4 {
    animation-delay: ($delayRange * 0.25);
  }
  .sk-cube-5 {
    animation-delay: ($delayRange * 0.5);
  }
  .sk-cube-6 {
    animation-delay: ($delayRange * 0.75);
  }
  .sk-cube-7 {
    animation-delay: 0s;
  }
  .sk-cube-8 {
    animation-delay: ($delayRange * 0.25);
  }
  .sk-cube-9 {
    animation-delay: ($delayRange * 0.5);
  }
}

@keyframes sk-cube-grid-scale-delay {
  0%,
  70%,
  100% {
    transform: scale3D(1, 1, 1);
  }
  35% {
    transform: scale3D(0, 0, 1);
  }
}
.page-loader {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
  background-color: rgb(241, 243, 252);
  left: 0;
  top: 0;
  &.modal {
    background-color: rgba(241, 243, 252, 0.7) !important;
  }
  &.with-opacity {
    background-color: rgba($white, 0.7) !important;
  }
}
