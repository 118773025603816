// Colors
// Color calculator - http://razorjam.github.io/sasscolourfunctioncalculator/
// Color names - http://name-of-color.com/

$gray: #999;
$black: #000;
$white: #fff;
$blue: #4a63a1;
$pale-blue: #7e97c5;
$light-blue: #b6c4dd;
$light-gray: #f1f3fc;
$dark: #242930;
$dark-blue: #37444e;
$brand: #42c1c6;
$dark-brand: #2d8a8d;
$light-brand: #71cc51;
$green: #32a31a;
$green-yellow: #c4e76a;
$yellow: #f3b506;
$orange: #f35f06;
$red: #f7786b;
$red-base: #d22e26;
$infleet-color: #42c1c6;

$base-font-sans-serif: "Raleway", "Arial", "Helvetica Neue", "Helvetica",
  sans-serif;
$base-font-serif: "Times New Roman", "Times", "Baskerville", "Georgia", serif;

// This is the default html and body font-size for the base rem value.
$rem-base: 16px;

// Body
$base-text-color: $dark-blue;
$base-background-color: $light-gray !default;
$font-size-base: 16px !default;
$line-height-base: 1.2 !default;
$base-font-family: $base-font-sans-serif !default;

$vertical-rhythm: ($line-height-base * 1em);

$base-min-width: 320px;

// Liks
$base-link-color: $green;

// Buttons
$btn-color: $white !default;
$btn-background: $green;
$btn-padding: 0.4em 0.7em !default;
$btn-font-size: $rem-base;

// Forms
$form-element-padding: $btn-padding !default;
$form-element-border-color: $gray !default;
$form-element-focus-border-color: $black !default;
$placeholder-color: $gray !default;

// Headers
$h1-font-size: 30px !default;
$h2-font-size: 18px;
$h3-font-size: 16px;
$h4-font-size: 21px !default;
$h5-font-size: 17px !default;
$h6-font-size: 15px !default;

$headings-font-family: inherit !default;
$headings-color: inherit !default;

$animation-speed: 0.3s;
