@import "../../../../../../assets/scss/vendors/include-media";
.range-number-component {
  .main-container {
    .col-xs-12 {
      @include media("<tablet") {
        margin-top: 20px;
      }
    }
    .input-holder {
      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      /* Firefox */
      input[type="number"] {
        -moz-appearance: textfield;
      }
    }
  }
}
