@import "../../../../../../assets/scss/base/variables";

.slider-modal {
  padding: 40px 15px 20px;
  .row {
    justify-content: center;
  }
  .input-range {
    margin-bottom: 40px;
  }
  .input-range__track {
    background-color: #cccccc;
  }
  .input-range__track--active,
  .input-range__slider {
    background-color: $brand;
  }
  .input-range__slider {
    border: 1px solid $brand;
  }
  label {
    display: flex;
    align-items: center;
    &.error {
      input {
        border: 1px solid #d22e26;
      }
    }
    span {
      margin-right: 15px;
    }
    input {
      width: 100%;
      padding: 10px 15px;
    }
  }
  .disable {
    opacity: 0.5;
    pointer-events: none;
  }
}
