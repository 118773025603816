@import "../../../../../assets/scss/vendors/include-media";
@import "../../../../../assets/scss/base/variables";

.PageHeader {
  border-bottom: 1px solid #cbd5e7;
  background-color: $white;
  min-height: 45px;
  @include media("<tablet") {
    display: none;
  }
  display: flex;
  align-items: center;
  .row {
    align-items: stretch;
    flex-wrap: nowrap;
    overflow: visible;
    width: 100%;
    height: 100%;
    min-height: 45px;
    justify-content: flex-end;
    @include media("<tablet") {
      margin: auto;
    }
  }
  .info-holder {
    display: none;
    @include media(">=tablet") {
      display: flex;
    }
  }
}
.step-col {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 8px 15px 0;
  @include media(">=tablet") {
    width: 70%;
  }
  @include media(">=widescreen") {
    width: 80%;
  }
  @include media("<tablet") {
    padding: 8px 2px;
  }
}
.steps {
  overflow: hidden;
  list-style: none;
  max-width: 770px;
  padding: 0 15px;
  margin: 0 auto;
  padding-bottom: 5px;
  display: flex;
  position: relative;
  justify-content: space-between;
  width: 100%;
  &:after {
    content: "";
    position: absolute;
    bottom: 5px;
    left: 0;
    right: 0;
    height: 3px;
    width: 100%;
    background-color: $light-blue;
    z-index: 1;
  }
  li {
    font-size: 12px;
    color: $light-blue;
    font-weight: 600;
    padding: 0 5px 10px;
    @include media(">=tablet") {
      font-size: 13px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 15px 10px;
    }
    @include media(">=desktop") {
      font-size: 14px;
      padding: 0 50px 10px;
    }
    &:first-child {
      padding-left: 0;
    }
    &:last-child {
      padding-right: 0;
      .circle-step {
        left: auto;
        right: -15px;
        transform: translateX(0);
      }
    }
    .circle-step {
      display: block;
      position: absolute;
      left: 50%;
      bottom: -14px;
      transform: translateX(-50%);
      width: 12px;
      height: 12px;
      border-radius: 100%;
      border: 3px solid $green;
      background-color: $white;
      z-index: 3;
      &:before {
        content: ";";
        position: absolute;
        background-color: $green;
        width: 100vw;
        right: 100%;
        height: 3px;
        bottom: 1px;
        z-index: 2;
      }
    }
    &.active {
      color: $green;
      pointer-events: none;
      .step-title {
        position: relative;
        .circle-step {
          display: block;
        }
      }
    }
    &.passed {
      color: $green;
    }
    &.disabled {
      pointer-events: none;
    }
    .fake-link {
      color: inherit;
      cursor: pointer;
    }
    .icon {
      font-size: 14px;
      margin-bottom: 5px;
      @include media(">=tablet") {
        margin-bottom: 0;
        margin-right: 10px;
        font-size: 16px;
      }
    }
    span {
      text-align: center;
      display: block;
      @include media(">=tablet") {
        display: inline-block;
      }
    }
  }
}
