@import "../../../../../assets/scss/vendors/include-media";
@import "../../../../../assets/scss/base/variables";

.leaflet-container {
  width: 100%;
  height: 276px;

  .leaflet-tile-container {
    width: 100%;
    height: 276px;
  }

  *:focus {
    outline: none;
  }
  .leaflet-left .leaflet-control,
  .leaflet-right .leaflet-control,
  .leaflet-draw-toolbar {
    margin: 0;
    border: none;
    border-radius: 0;
  }
  .leaflet-control .leaflet-draw-toolbar > a,
  .leaflet-right .leaflet-control a {
    border-radius: 0;
    background: rgba(126, 151, 197, 0.7);
    width: 40px;
    height: 40px;
    line-height: 40px;
    color: #fff;
    border: none;
    &.leaflet-control-zoom-out {
      border-top: 1px solid rgba(255, 255, 255, 0.7);
    }
  }
  .leaflet-draw-actions {
    left: 40px;
    a {
      background: rgba(126, 151, 197, 0.7);
    }
  }
  .leaflet-touch .leaflet-control-layers,
  .leaflet-touch .leaflet-bar,
  .leaflet-touch .leaflet-control-layers,
  .leaflet-touch .leaflet-bar {
    border: none;
  }
  .leaflet-touch .leaflet-bar a:last-child,
  .leaflet-touch .leaflet-bar a:first-child,
  .leaflet-touch .leaflet-bar a * {
    border-radius: 0;
  }
  .leaflet-control .toggleEdit {
    border: none;
    width: 40px;
    height: 40px;
    display: inline-block;
    background: rgba(126, 151, 197, 0.7);
    color: #fff;
    font-size: 20px;
    line-height: 40px;
  }
  .leaflet-control-zoom-fullscreen {
    font-size: 20px;
    line-height: 40px;
  }

  .leaflet-draw-draw-polygon,
  .leaflet-draw-draw-circle,
  .leaflet-draw-edit-remove {
    position: relative;
    font-size: 20px;
  }
  .leaflet-draw-edit-remove .leaflet-disabled {
    color: rgba(255, 255, 255, 0.7);
  }

  .leaflet-draw-draw-polygon:before,
  .leaflet-draw-draw-circle:before,
  .leaflet-draw-edit-remove:before,
  .leaftleft-drawing-toggle:before {
    font-size: 20px;
    color: #fff;
    display: block;
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    line-height: 40px;
    top: 0;
    left: 0;
    font-family: "icomoon" !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-feature-settings: normal;
    font-variant: normal;
    text-transform: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .leaflet-draw-draw-polygon:before {
    content: "\e995";
  }
  .leaflet-draw-draw-circle:before {
    content: "\e994";
  }
  .leaflet-draw-edit-remove:before {
    content: "\e922";
  }

  .leaflet-draw-draw-polygon:after {
    display: block;
    content: "";
    border-top: 1px solid rgba(255, 255, 255, 0.7);
    top: 0;
    left: 4px;
    width: 32px;
    position: absolute;
  }

  .leaflet-control .leaftleft-drawing-toggle {
    width: 40px;
    height: 40px;
    background: rgba(126, 151, 197, 0.7);
    cursor: pointer;
    text-align: center;
    position: absolute;
    top: 0;
    left: 0;
    &:before {
      content: "\e919";
    }
  }

  .leaflet-draw.leaflet-control {
    margin-top: 40px;
  }

  .map-label {
    .map-marker {
      position: relative;
      .marker-icon {
        position: absolute;
        background: url("../../../../../assets/images/marker-map-home.png")
          no-repeat;
        width: 32px;
        height: 32px;
        bottom: 0;
        transform: translate(-50%, 0);
        .count-label {
          position: absolute;
          background: $red;
          border-radius: 10px;
          color: $white;
          padding: 0 5px;
        }
      }
      &.active {
        .marker-icon .count-label {
          background: $light-brand;
        }
      }
    }
  }

  .selected-company {
    position: absolute;
    top: 10px;
    left: 10px;
    z-index: 1000;
    height: 300px;
    max-height: calc(100% - 20px);
    width: 256px;
    overflow: hidden;
    cursor: default;
    .header-company {
      background: $pale-blue;
      position: relative;
      padding: 10px 17px;
      .close {
        color: $white;
        position: absolute;
        right: 5px;
        top: 5px;
        cursor: pointer;
      }
      h2 {
        font-size: 13px;
        line-height: 14px;
        color: $white;
        margin: 0 0 5px;
      }
      p {
        font-size: 13px;
        line-height: 14px;
        color: $white;
        margin: 0;
        padding: 0;
      }
    }
    .top-hits {
      background: $white;
      height: 250px;
      max-height: calc(100% - 53px);
      ul {
        padding: 0;
        margin: 0;
        list-style: none;
        li {
          overflow: hidden;
          position: relative;
          padding: 10px;
          &:after {
            content: "";
            display: block;
            position: absolute;
            bottom: 0;
            left: 10px;
            width: calc(100% - 20px);
            height: 1px;
            background: $light-blue;
          }
          .photo-holder {
            color: $blue;
            border: 1px solid $blue;
            font-size: 17px;
            line-height: 30px;
            width: 30px;
            text-align: center;
            height: 30px;
            border-radius: 40px;
            float: left;
          }
          .content-holder {
            overflow: hidden;
            text-align: left;
            padding: 0 0 0 10px;
            h3 {
              text-transform: uppercase;
              font-size: 12px;
              line-height: 13px;
              margin: 0 0 5px;
              font-weight: 500;
              color: $blue;
            }
            p {
              color: $pale-blue;
              margin: 0;
              font-size: 12px;
              line-height: 13px;
            }
          }
        }
      }
    }
  }

  .leaflet-overlay-pane {
    z-index: 850;
  }
}

.leaflet-control-attribution {
  display: none;
}
