@import "../../../assets/scss/base/variables";
@import "../../../assets/scss/vendors/include-media";
.search-holder {
  padding-top: 15px;
  .search-heading {
    padding: 0 25px;
    background-color: transparent;
    p {
      padding-top: 1rem;
      margin: 0;
    }
    .search-table {
      position: relative;
      cursor: pointer;
      &.active {
        .icon-search {
          position: absolute;
          right: 15px;
          top: 50%;
          font-size: 24px;
          color: $green;
          transform: translateY(-50%);
        }
        input {
          height: 40px;
          width: 100%;
          border-radius: 20px;
          border: none;
          cursor: pointer;
          padding: 0 45px 0 20px;
          border-radius: 20px;
          @include media("<tablet") {
            width: 100%;
          }
        }
        &:hover {
          input {
            cursor: text;
            background-color: $white;
          }
        }
      }
      &:hover {
        input {
          background-color: $white;
        }
      }
    }
  }
}
