@import "../../../assets/scss/vendors/include-media";
@import "../../../assets/scss/base/variables";

.create-filter {
  h3 {
    color: $blue;
    font-size: 18px;
    line-height: 20px;
    font-weight: 600;
  }

  &.single-tab-modal header {
    display: flex;
    align-items: center;
  }

  .stepsList {
    margin: 0 0 0 auto;
    padding: 0 30px 0 0;
    list-style: none;
    display: flex;
    .step {
      position: relative;
      display: inline-block;
      width: 30px;
      height: 30px;
      background: $white;
      border-radius: 20px;
      color: $dark-brand;
      font-weight: 600;
      text-align: center;
      line-height: 30px;
      margin: 0 0 0 20px;
      pointer-events: none;
      opacity: 0.7;
      &:first-child {
        margin: 0;
        &:after {
          display: none;
        }
      }
      &:after {
        content: "";
        display: block;
        position: absolute;
        width: 20px;
        height: 2px;
        right: 100%;
        top: 50%;
        transform: translate(0, -50%);
        background: $dark-brand;
      }
      &.active {
        opacity: 1;
        pointer-events: all;
      }
      .checkmark {
        position: absolute;
        width: 100%;
        height: 100%;
        background: $white;
        color: $brand;
        top: 0;
        left: 0;
        border-radius: 20px;
        .icon {
          font-weight: 700;
          line-height: 31px;
        }
      }
      span {
      }
    }
  }

  .step-content {
    padding: 0 0 30px;
    .step-title {
      margin: 0 0 20px;
      &.no-m {
        margin: 0;
      }
    }
    .step-subtitle {
      color: $blue;
      margin: 0 0 20px;
      font-weight: 600;
    }
    input {
      width: 80%;
      border: 1px solid $brand;
    }

    .dropzone {
      width: 100%;
      height: 230px;
      border: 2px dashed $light-blue;
      background: $white;
      position: relative;
      cursor: pointer;
      &.active-area {
        border-color: $brand;
      }
      &:focus,
      * {
        outline: none;
      }
      .drop-container {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        text-align: center;
        padding: 40px 0 0;
        .title-icon {
          width: 70px;
          height: 70px;
          display: inline-block;
          border: 2px solid $brand;
          border-radius: 70px;
          color: $brand;
          font-size: 30px;
          line-height: 70px;
        }
        .description {
          padding: 22px 0 0;
          .desc-title {
            font-size: 19px;
            margin: 0 0 7px;
          }
          .desc-text {
            color: $blue;
            font-style: italic;
            .remove-file {
              font-size: 23px;
              cursor: pointer;
              display: inline-block;
              margin: 0 0 0 4px;
              vertical-align: middle;
            }
          }
        }
      }
    }
  }

  .custom-checkbox {
    vertical-align: top;
    margin: 0 10px 0 0;
  }

  .company-id-select {
    margin: 0 0 20px;
  }

  .checkboxes-holder {
    overflow: hidden;
    .checkbox-item {
      display: inline-block;
      padding: 0 10px 10px 0;
      label {
        line-height: 15px;
        height: 15px;
        display: inline-block;
        cursor: pointer;
      }
    }
  }

  .react-select__control {
    &.react-select__control--is-focused {
      border-color: hsl(0, 0%, 100%);
      box-shadow: none;
    }
  }

  .actions-holder {
    .btn {
      margin: 0 0 0 30px;
      &:first-child {
        margin: 0;
      }
    }
  }

  .company-id-check {
    padding: 0 0 10px;
    label {
      display: inline-block;
      height: 15px;
      line-height: 15px;
      cursor: pointer;
    }
  }

  .columns-list {
    list-style: none;
    margin: 0;
    padding: 0;
    .column-item {
      margin: 0 0 15px;
      .column-title {
        color: $blue;
        margin: 0 0 20px;
        font-weight: 600;
        margin: 0 0 7px;
      }
    }
  }

  .selected-company-type {
    display: inline-block;
    height: 20px;
  }

  .radio-holder {
    display: flex;
    margin: 0 0 30px;
    .radio-item {
      margin: 0 20px 0 0;
      label {
        cursor: pointer;
        display: flex;
        line-height: 15px;
      }
    }
  }

  .formats-list {
    background: $white;
    padding: 10px 20px 0;
    text-align: center;
    margin: 40px -20px 0;
    label {
      cursor: pointer;
    }
    .filter-title {
      color: $blue;
      font-weight: 600;
    }
    .info-text {
      text-align: left;
      color: $blue;
      font-size: 12px;
      padding: 0 20px;
    }
    ul {
      margin: 0;
      padding: 0;
      list-style: none;
      display: flex;
      justify-content: center;
      li {
        width: 33.33%;
      }
    }
  }
}
