@import "../../../../assets/scss/base/variables";
@import "../../../../assets/scss/vendors/include-media";

.dashboard-account-component {
  .header-section {
    padding-bottom: 50px;
    box-shadow: 0 26px 32px -42px rgba(0, 0, 0, 0.9);
    @include media("<tablet") {
      padding-bottom: 0;
      box-shadow: none;
    }
    h2 {
      font-size: 30px;
    }
  }

  .welcome-title {
    color: $blue;
    font-size: 25px;
    text-align: center;
    font-weight: bold;
  }

  .my-account {
    // margin-top: 50px;
    padding: 0px 25px;
    &__label {
      font-size: 22px;
      color: $blue;
      font-weight: bold;
    }
    &__box-list {
      margin-top: 15px;
      justify-content: space-between;
      display: flex;
      flex-wrap: wrap;
      .box-entry {
        margin: 5px 0;
        width: calc(50% - 5px);
        background-color: $white;
        padding: 10px 7px;
        text-align: center;

        .box-title {
          font-size: 16px;
          @media (max-width: "1024px") {
            font-size: 14px;
          }
        }

        .box-content {
          font-size: 14px;
          margin-top: 5px;
          span {
            color: $green;
            font-size: 22px;
            @media (max-width: "1024px") {
              font-size: 18px;
            }
          }
          p {
            color: $green;
            font-size: 18px;
            margin: 13px 0 0;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            font-weight: 500;
          }
          .link-wrapper {
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 10px 0 0;
            .icon {
              font-size: 12px;
            }
          }
          .link-entry {
            border: none;
            background-color: transparent;
            color: $green;
            display: flex;
            font-size: 12px;
            font-weight: bold;
            position: relative;
            @include media("<tablet") {
              margin-top: 5px;
              margin-left: 0;
            }
            &:after {
              content: "";
              position: absolute;
              bottom: -3px;
              left: 0;
              width: 0;
              height: 2px;
              background-color: $green;
              -webkit-transition: width 0.3s;
              transition: width 0.3s;
            }
            &:hover {
              color: $green;
              &:after {
                width: 100%;
                @include media("<tablet") {
                  width: 0;
                }
              }
            }
            span {
              font-size: 14px;
              margin-right: 5px;
            }
          }
        }
      }
    }
  }
}

.single-tab-modal-content > p {
  color: $dark-blue;
  font-weight: 400;
}

.segmentation-title {
  font-size: 22px;
  color: $blue;
  font-weight: bold;
  margin: 10px 0 0;
}
.primary-list {
  padding: 0;
  margin: 0;
  list-style: none;
  li {
    margin-bottom: 10px;
  }
  .list-heading {
    font-size: 16px;
    color: $blue;
    font-weight: 600;
    line-height: 2.5;
  }
  .under-list {
    list-style: none;
    margin: 0;
    padding: 0;
    li {
      padding-left: 20px;
      position: relative;
      .icon {
        position: absolute;
        top: 5px;
        left: 0;
        margin: 0;
        padding: 1px;
        width: 13px;
        height: 13px;
        border-radius: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        line-height: 0;
        color: $white;
        &.icon-confirm {
          font-size: 7px;
          font-weight: 700;
          background-color: $green;
        }
        &.icon-close {
          font-weight: 400;
          font-size: 8px;
          background-color: $red;
        }
      }
    }
  }
}
