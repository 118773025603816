@import "../../../../../../assets/scss/base/variables";
@import "../../../../../../assets/scss/vendors/include-media";
.modal-multiple-text {
  display: flex;
  align-items: center;
  .modal-content {
    margin-right: 10px;
    &.exclude {
      .input-wrapper .left-items .label-entry {
        background-color: $red;
      }
    }

    .input-wrapper {
      padding: 10px 0;
      .left-items {
        justify-content: flex-start;
        .label-entry {
          float: left;
          margin-right: 10px;
          background: #b6c4dd;
          padding: 8px 12px;
          border-radius: 25px;
          color: #4a63a1;
          position: relative;
          cursor: pointer;
          margin-bottom: 5px;
          &:hover {
            background: $pale-blue;

            .remove-item {
              opacity: 1;
            }
          }
          .remove-item {
            position: absolute;
            background: $dark-blue;
            font-size: 10px;
            line-height: 9px;
            color: $white;
            padding: 3px;
            border-radius: 8px;
            right: -3px;
            top: -3px;
            text-align: center;
            width: 15px;
            height: 15px;
            transition: all 0.1s ease-in-out;
            opacity: 0;
            cursor: pointer;
          }
        }
      }
      .input-footer-container {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        @include media("<tablet") {
          flex-direction: column;
          justify-content: center;
          align-items: initial;
        }
        input {
          outline: none;
          padding: 10px;
          width: 100%;
          max-width: 290px;
          border: 1px solid #b6c4dd;
          margin-bottom: 10px;
          margin-right: 25px;
          @include media("<tablet") {
            margin-left: auto;
            margin-right: auto;
          }
        }
      }
    }
  }
}
