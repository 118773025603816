@import "../../../../../assets/scss/base/variables";
@import "../../../../../assets/scss/vendors/include-media";

.modal-footer {
  .switch-modal {
    display: flex;
    justify-content: center;
    @include media(">=tablet") {
      justify-content: flex-start;
    }
    .switch {
      padding: 0 10px;
    }
    .switch-label {
      color: $dark-blue;
      font-weight: 600;
      cursor: pointer;
      transition: color 0.3s;
      &.label-on {
        &:hover {
          color: $green;
          text-decoration: underline;
        }
      }
      &.label-off {
        &:hover {
          color: $red;
          text-decoration: underline;
        }
      }
    }
  }
  &.include {
    .label-on {
      text-decoration: underline;
      color: $green;
    }
  }
  &.exclude {
    .label-off {
      text-decoration: underline;
      color: $red;
    }
  }
}
