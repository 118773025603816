@import "../../../assets/scss/vendors/include-media";
@import "../../../assets/scss/base/variables";

.footer {
  display: block;
  position: absolute;
  @include media("<tablet") {
    position: fixed;
  }
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba($dark, 0.9);
  z-index: 10;
  .alert-footer {
    background-color: #f79288;
    font-weight: 700;
    color: #37444e;
    vertical-align: middle;
    font-size: 14px;
    text-align: center;
    line-height: 25px;
  }
  .container {
    width: 100%;
  }
  .row {
    padding: 10px 15px;
    justify-content: center;
    align-items: center;
    @include media("<tablet") {
      justify-content: space-between;
      flex-wrap: nowrap;
    }
  }
  .btn-holder {
    button {
      font-size: 16px;
      vertical-align: middle;
      .icon-arrow-thin-right {
        font-size: 20px;
        line-height: 0;
        padding: 0;
        font-weight: 300;
        margin-left: 20px;
        vertical-align: middle;
        @include media("<tablet") {
          display: none;
        }
      }
    }
  }
  span {
    display: inline-block;
    color: $white;
    padding: 0 5px;
    font-size: 16px;
    line-height: 1.45;
    font-weight: 500;
    @include media("<tablet") {
      font-size: 14px;
    }
  }
  .description {
    max-width: 125px;
    @include media("<tablet") {
      width: 100%;
      max-width: none;
    }
  }
  .text-holder {
    display: flex;
    align-items: flex-end;
    margin: 0 auto;
    @include media("<tablet") {
      flex-wrap: wrap;
      margin: 0;
    }
  }
  .number {
    line-height: 1;
    font-size: 50px;
    color: $light-brand;
    @include media("<tablet") {
      font-size: 30px;
    }
  }
  .go-back-link {
    max-width: 50px;
    button {
      background-color: transparent;
      border: none;
      font-weight: 700;
      color: $light-blue;
      vertical-align: middle;
      text-decoration: underline;
      font-size: 13px;
      span {
        color: $light-blue;
        display: inline-block;
        vertical-align: middle;
        margin-right: 3px;
        font-size: 14px;
      }
      &:hover {
        text-decoration: none;
      }
    }
    @include media("<tablet") {
      display: none;
    }
  }
  .download-link {
    color: $white;
    margin-right: 40px;
    text-decoration: underline;
    font-weight: 700;
    cursor: pointer;
    &:hover {
      text-decoration: none;
    }
    @include media("<tablet") {
      display: none;
    }
  }
}
