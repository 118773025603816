.single-tab-modal {
  .single-tab-modal-content {
    padding: 30px;
    form {
      padding: 10px 0;
    }
    .radio-row label {
      padding: 0;
      margin: 10px;
    }
    .btn-row {
      margin-top: 40px;
    }
  }
}
