@import "../../../assets/scss/base/variables";
@import "../../../assets/scss/vendors/include-media";

.establishment {
  padding: 0 50px 50px 50px;
  @media (max-width: 600px) {
    padding: 0 10px;
  }
  .row {
    margin: 0;
    overflow: auto;
  }
}

.PageHeader {
  .return-button-wrapper {
    display: flex;
    justify-content: left;
    align-items: center;
    width: 100%;
    margin: 0 0 0 50px;
    .return-arrow {
      transform: scaleX(-1);
      cursor: pointer;
    }
    .return-button {
      font-family: "Raleway", "Arial", "Helvetica Neue", "Helvetica", sans-serif;
      font-weight: 600;
      font-size: 14px;
      color: $green;
      margin: 0 0 0 10px;
      cursor: pointer;
    }
  }
}

.etabTitle {
  margin: 0 0 30px 0;
  border-bottom: 1px solid #b6c4dd;
  p {
    margin: 0;
  }
  .etabTitle-button {
    margin: 40px 20px;
  }
}

.dropdown {
  margin: 10px 0;
  .dropdown-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: $light-brand;
    color: $white;
    padding: 10px;
    cursor: pointer;
    h2 {
      color: $white;
      font-size: 20px;
    }
    .icon-arrow-right {
      font-weight: 700;
      transition: all 0.3s ease-in-out;
    }
  }

  .dropdown-content {
    background-color: $white;
    padding: 20px;
    .centerText {
      text-align: center;
      font-weight: 700;
      font-size: 16px;
      padding: 10px 0;
    }
    .rightText {
      text-align: right;
      font-weight: 700;
      font-size: 16px;
      padding: 10px 0;
    }
    .leftText {
      text-align: left;
      font-weight: 700;
      font-size: 16px;
      padding: 10px 0;
    }
    .row {
      display: flex;
    }
    .row .table-holder {
      overflow: hidden;
      box-shadow: 0px 0px 16px -5px rgba(0, 0, 0, 0.75);
      margin: 20px 0;
      width: 100%;
      thead th {
        color: $blue;
        background: $light-gray;
        z-index: 5;
        cursor: auto;
      }

      th,
      td {
        border-right: 2px solid $light-blue;
        padding: 15px;
        p {
          margin: 0;
        }
      }
      td > span {
        border: 1px solid;
        border-radius: 2px;
      }
      th:last-of-type,
      td:last-of-type {
        border-right: none;
      }
      tr {
        border-bottom: 2px solid $light-blue;
      }

      tr:last-of-type {
        border-bottom: none;
      }
      .expandable-td {
        font-weight: 500;
        padding-left: 6px;
      }
      .expandable-td > span {
        margin: 0 5px;
      }
    }
  }
}

.general-information,
.contact-detail {
  display: flex;
  flex-direction: column;
  align-items: space-between;
  .row {
    margin: 5px 0 !important;
    display: flex;
    justify-content: flex-start;
  }
  .col:first-child {
    width: 350px;
  }
  .row > .col {
    font-weight: 500;
    max-width: 350px;
  }
  .row > .col:first-child {
    color: $blue;
  }
  .row > .col a {
    color: $dark-blue;
    cursor: pointer;
  }
  .row > .col a:hover {
    text-decoration: underline;
  }
}

.map-div {
  max-width: 500px;
  margin: 10px auto;
}

.key-values {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px !important;
  .card {
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 2px solid rgb(230, 230, 230);
    border-radius: 4px;
    padding: 5px;
    height: 170px;
    width: 200px;
    margin: 5px auto 10px;
  }
  .card > p {
    color: $blue;
    font-size: 16px;
    font-weight: bold;
    margin: 10px 0;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  .card > span {
    font-weight: bold;
    margin: 10px 0;
    font-size: 32px;
    color: $dark-blue;
  }
}

.visual-graphs {
  margin: 20px auto 0 !important;
  display: flex;
  justify-content: space-around;
  .col p:first-child {
    color: $blue;
    font-size: 16px;
    font-weight: bold;
  }
  canvas {
    margin: 10px;
    padding: 0 10px;
    border: 2px solid $light-gray;
    width: 450px !important;
    height: auto !important;
  }
}

.leaders {
  padding: 0;
  > li {
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 2px solid rgb(230, 230, 230);
    border-radius: 4px;
    padding: 5px;
    height: 200px;
    width: 250px;
    margin: 5px auto 10px;
    text-align: center;
    > span {
      margin: 10px auto;
      font-size: 40px;
    }
    > p:first-of-type {
      color: $light-brand;
      font-weight: bold;
      height: 40px;
    }
  }
}

.actionnary {
  .row {
    justify-content: center;
  }
  .card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 2px solid rgb(230, 230, 230);
    border-radius: 4px;
    padding: 5px;
    height: 150px;
    width: 250px;
    margin: 5px 20px;
  }
  .card > span {
    font-size: 40px;
    margin: 10px 0;
  }
  .card p {
    margin: 0;
  }
  .card > p:first-of-type {
    color: $blue;
    font-size: 20px;
    font-weight: bold;
    height: 40px;
    text-align: center;
  }
  .card > p:last-child {
    font-weight: bold;
    font-size: 32px;
    color: $dark-blue;
  }
  .row .table-holder {
    overflow: hidden;
    box-shadow: 0px 0px 16px -5px rgba(0, 0, 0, 0.75);
    margin: 20px 0;
    width: 100%;
    thead th {
      color: $blue;
      background: $light-gray;
      cursor: auto;
      z-index: 5;
    }
    th,
    td {
      border-right: 2px solid $light-blue;
      padding: 15px;
      p {
        margin: 0;
      }
    }
    th:last-of-type,
    td:last-of-type {
      border-right: none;
    }
  }
  .icon-filiale::before {
    color: #5aa041;
  }
}

.score {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  .icon {
    font-size: 80px;
    width: 100px;
    height: 100px;
    border: 2px solid;
    border-radius: 100%;
    display: flex;
    justify-content: center;
  }
  .card {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 4px;
    padding: 5px;
    height: 300px;
    width: 300px;
    text-align: center;
    margin: 5px 20px;
  }
  .card > p:first-of-type {
    color: $blue;
    font-size: 20px;
    font-weight: bold;
    height: 40px;
  }
  .card > p:nth-child(2) {
    font-size: 42px;
    font-weight: bold;
    height: 60px;
    display: flex;
    align-items: center;
  }
  .card > p {
    font-weight: bold;
  }
  .card .backgroundText {
    background-color: $light-gray;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    width: 100%;
  }
  .card .noScore {
    background-color: $light-gray;
    font-size: 42px;
    font-weight: bold;
  }
}

.icon-scoring-green {
  border-color: $green;
  color: $green;
  align-items: flex-end;
  font-size: 66px;
  padding: 0;
  &:before {
    content: "\e99a";
  }
}
.icon-scoring-yellow {
  border-color: $yellow;
  color: $yellow;
  align-items: flex-end;
  font-size: 66px;
  padding: 0;
  &:before {
    content: "\e99a";
  }
}
.icon-scoring-orange {
  border-color: $orange;
  color: $orange;
  align-items: flex-end;
  font-size: 66px;
  padding: 0;
  &:before {
    content: "\e99a";
  }
}
.icon-scoring-red {
  border-color: $red-base;
  color: $red-base;
  align-items: flex-end;
  font-size: 66px;
  padding: 0;
  &:before {
    content: "\e99a";
  }
}

.donneeFinanciere {
  .parameter {
    display: flex;
    justify-content: center;
    button {
      margin: 15px 5px;
    }
  }
}
