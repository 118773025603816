@import "../../../assets/scss/vendors/include-media";
@import "../../../assets/scss/base/variables";

.ReactModal__Overlay {
  z-index: 9 !important;
  background-color: rgba($dark-blue, 0.75) !important;
}

.disable {
  pointer-events: none;
}

.styles_modal__gNwvD {
  max-width: 900px;
  width: 100%;
  padding: 0;
  background-color: #f1f3fc;
}

.modal.create-filter-modal {
  max-width: 750px;
}

.modal-tabs {
  .tab-headings {
    padding-right: 50px;
    background-color: #e1e7f1;
    @include media("<tablet") {
      padding-right: 0;
    }

    .tab-link {
      position: relative;
      transition:
        background-color 0.3s,
        color 0.3s;
      display: inline-block;
      outline: none;
      background-color: #e1e7f1;
      border: none;
      padding: 10px 7px;
      color: $white;
      font-size: 14px;
      font-weight: 500;
      color: $dark-blue;
      border-right: 1px solid #91a8d0;
      border-left: 1px solid $white;
      @include media(">=tablet") {
        font-size: 16px;
        padding: 13px 10px;
      }

      &:after {
        position: absolute;
        top: 100%;
        left: 50%;
        transform: translateX(-50%);
        display: none;
        content: "";
        width: 0;
        height: 0;
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
        border-top: 8px solid $dark-blue;
        z-index: 11;
      }

      &.tab-link-active {
        opacity: 1;
        background-color: $dark-blue;
        color: $light-brand;
        font-weight: 500 !important;
        @include media("<tablet") {
          border-bottom: 3px solid $light-brand;
        }
        @include media(">=tablet") {
          &:after {
            display: block;
          }
        }
      }

      &:hover {
        background-color: $dark-blue;
        color: $light-brand;
      }

      &:last-child {
        border-right: none;
      }

      &:first-child {
        border-left: none;
      }
      @include media("<tablet") {
        border: none;
        border-bottom: 1px solid #ccc;
      }
    }
  }

  .tab-content {
    background-color: #f1f3fc;
    position: relative;
    z-index: 10;
    margin-top: -10px;
    font-size: 14px;
    padding: 20px 10px 10px;
    @include media(">=tablet") {
      font-size: 16px;
      padding: 60px 20px 20px;
    }
  }
}

.styles_closeButton__20ID4 {
  outline: none;
  top: 0;
  right: 0;
  padding: 4px;
  @include media(">=tablet") {
    padding: 9px;
  }

  svg {
    fill: #fff;
  }

  &:hover {
    svg {
      fill: #000;
    }
  }
}

.modal-btn-holder {
  display: inline-block;
  margin-right: 10px;
  margin-bottom: 10px;
  @include media(">=tablet") {
    margin-right: 15px;
  }
}

.modal-btn {
  display: inline-block;
  padding: 5px 10px;
  border-radius: 25px;
  background-color: #b6c4dd;
  text-align: center;
  color: #4a63a1;
  font-weight: 500;
  border: 1px solid #b6c4dd;
  outline: none;
  cursor: pointer;
  font-size: 12px;
  @include media(">=tablet") {
    padding: 8px 12px;
    font-size: 14px;
  }

  &:hover,
  &.active {
    color: $green;
    border: 1px solid $green;
    background-color: transparent;
  }

  &.active-in-filter {
    color: $white;
    border: 1px solid $green;
    background-color: $green;
  }
}

.tab-description {
  position: relative;
  padding-left: 30px;

  &:before {
    position: absolute;
    top: 0;
    left: 5px;
    color: $dark-blue;
    content: "\e911";
    font-family: "icomoon" !important;
  }
  .sub-info {
    font-style: italic;
    font-size: 13px;
  }
}

.checkboxes-wrapper {
  padding: 15px 0;
  margin: 0 -15px;
  @include media(">=tablet") {
    padding: 30px 0;
    display: flex;
    justify-content: space-between;
  }

  .col {
    width: 100%;
    padding: 0 15px;
    margin-bottom: 15px;
    @include media(">=tablet") {
      width: 50%;
      margin-bottom: 0;
    }

    &.col12 {
      width: 100%;
    }
  }

  ul {
    height: 100%;
    list-style: none;
    margin: 0;
    padding: 20px;

    li {
      margin-bottom: 8px;
    }
  }

  .checkboxes-list {
    padding: 20px;
    overflow: auto;
    @include media("<tablet") {
      padding: 10px 20px 10px 10px;
    }
  }
}

.modal-content {
  &.exclude {
    .custom-checkbox {
      &:hover {
        background-color: $red;
        border-color: $red;
      }

      &.checked {
        border-color: $red;
        background-color: $red;
      }
    }

    .checkboxes-list .react-checkbox-tree {
      .rct-checkbox {
        &:hover {
          background-color: $red;
        }

        .rct-icon.rct-icon-check {
          background-color: $red;
        }

        .rct-icon.rct-icon-half-check {
          background-color: $red;
          border-color: $red;
        }
      }
    }

    .current-checkboxes {
      .icon-close {
        color: $red;
      }
    }

    .btn {
      background-color: $red;
      background-image: none;
    }

    .cities-list {
      .city-item {
        background-color: $red;
      }
    }
  }

  &.unit-euro .input-holder:after {
    content: "\20AC";
  }

  &.unit-percent .input-holder:after {
    content: "\0025";
  }

  .btn-holder {
    input {
      border: none;
      padding: 9px 25px;
      line-height: 1.6;
    }
  }

  .inputs-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 15px 0 0;

    input {
      line-height: 1.6;
      width: 100%;
      padding: 8px 10px;
      border: 1px solid #b6c4dd;
    }

    &.single {
      justify-content: flex-start;
    }
  }

  .input-holder {
    position: relative;
    display: block;
    max-width: 200px;

    input {
      line-height: 1.6;
      width: 100%;
      padding: 8px 40px 8px 15px;
      outline: none;

      &:focus {
        border-color: $light-blue;
      }
    }

    &:after {
      position: absolute;
      right: 15px;
      top: 50%;
      transform: translateY(-50%);
      font-size: 20px;
      color: $light-blue;
    }
  }

  .select {
    .react-select__control {
      min-height: 0;
      border-radius: 0;
      border: none;
      cursor: pointer;
      background-color: $light-blue;
    }

    .react-select__control--is-focused {
      box-shadow: none;
      border-radius: 0;

      &:hover {
        border-color: $black;
      }

      .react-select__indicators {
        &:after {
          color: $white;
        }
      }
    }

    .react-select__control--menu-is-open {
      .react-select__indicators {
        &:after {
          transform: rotate(180deg);
        }
      }
    }

    .react-select__single-value {
      color: $blue;
      font-weight: 600;
    }

    .react-select__value-container {
      padding: 8px 15px;
    }

    .react-select__menu {
      border-radius: 0;
      background-color: $light-blue;

      .react-select__menu-list {
        .react-select__option {
          cursor: pointer;

          &:hover {
            background-color: $light-gray;
          }
        }

        .react-select__option--is-selected {
          background-color: $white;
          color: $black;
        }
      }
    }
  }

  .modal-box {
    border: 1px solid #b6c4dd;
    padding: 20px;
    background: #fff;
  }

  .concepts-list {
    padding: 0;
    margin: 0;
    list-style: none;

    li {
      padding: 0 0 7px;

      &:last-child {
        padding: 0;
      }
    }

    .remove-item {
      display: inline-block;
      width: 16px;
      height: 16px;
      background: #37444e;
      color: #fff;
      text-align: center;
      border-radius: 20px;
      vertical-align: top;
      font-size: 10px;
      margin: 3px 3px 0 0;
      line-height: 17px;
      cursor: pointer;
    }
  }

  .col-holder {
    border: 1px solid $light-blue;
    overflow: auto;
    max-height: 260px;
    height: 100%;
    background: #fff;
  }

  .search-cities {
    position: relative;
    margin-bottom: 20px;

    .cities-autocomplete {
      border: 1px solid $light-blue;
      background: $white;
      position: absolute;
      top: 100%;
      width: 100%;
      z-index: 100;

      .no-results {
        margin: 0;
        padding: 10px 0;
      }

      ul {
        margin: 0;
        padding: 5px 15px;
        list-style: none;

        .item {
          padding: 8px 5px;
          border-top: 1px solid $light-blue;
          cursor: pointer;

          &:first-child {
            border: none;
          }

          &.selected,
          &:hover {
            background: $light-blue;
          }
        }
      }
    }

    .loader {
      position: absolute;
      background: rgba($white, 0.7);
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      padding: 5px 0 0 10px;

      .lds-dual-ring {
        display: inline-block;
        width: 64px;
        height: 64px;
      }

      .lds-dual-ring:after {
        content: " ";
        display: block;
        width: 30px;
        height: 30px;
        margin: 1px;
        border-radius: 50%;
        border: 5px solid $brand;
        border-color: $brand transparent $brand transparent;
        animation: lds-dual-ring 1.2s linear infinite;
      }

      @keyframes lds-dual-ring {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(360deg);
        }
      }
    }
  }

  .refresh-btn {
    background: none;
    border: none;

    .icon {
      color: $green;
      font-size: 22px;
      line-height: 23px;
      vertical-align: middle;
    }
  }

  .cities-wrapper {
    padding: 0 0 25px;

    .col-holder {
      position: relative;
      overflow: hidden;

      &:after {
        content: "";
        display: block;
        height: 20px;
        width: 93%;
        position: absolute;
        left: 3%;
        top: -24px;
        box-shadow: 0px -3px 17px rgba(0, 0, 0, 0.75);
        border-radius: 20px;
      }
    }
    .refresh-btn-holder {
      @include media("<tablet") {
        margin: 5px 0;
      }
    }
  }

  .cities-list {
    padding: 20px;
    overflow: auto;
    max-height: 255px;

    .city-item {
      float: left;
      margin: 0 0 15px 15px;
      background: $light-blue;
      padding: 8px 12px;
      border-radius: 25px;
      color: $blue;
      position: relative;
      cursor: pointer;

      &:first-child {
        margin-left: 0;
      }

      .remove-item {
        position: absolute;
        background: $dark-blue;
        font-size: 10px;
        line-height: 9px;
        color: $white;
        padding: 3px;
        border-radius: 8px;
        right: -3px;
        top: -3px;
        text-align: center;
        width: 15px;
        height: 15px;
        transition: all 0.1s ease-in-out;
        opacity: 0;
        cursor: pointer;
      }

      &:hover {
        background: $pale-blue;

        .remove-item {
          opacity: 1;
        }
      }
    }
  }
}

.react-select__indicators {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $dark-blue;
  width: 45px;
  position: relative;
  font-family: "icomoon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  svg {
    display: none;
  }

  &:after {
    position: absolute;
    content: "\e91c";
    color: $light-brand;
    font-weight: 700;
    font-size: 22px;
  }

  .react-select__indicator-separator {
    display: none;
  }

  &:hover {
    &:after {
      color: $white;
    }
  }
}

.single-tab-modal {
  background-color: $green;

  header {
    padding: 15px 30px 14px;
    color: $white;
    font-size: 16px;
  }

  .single-tab-modal-content {
    background-color: $light-gray;
    padding: 60px 20px 20px;
    position: relative;
  }
}

/* package default styles */
.component-slider {
  position: relative;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
}

.component-slider .slider-container {
  margin: 0 20px;
  margin-left: 0;
  overflow: hidden;
  @include media("<tablet") {
    margin: 0;
  }
}

.component-slider .slider-content {
  padding-bottom: 10px;
  display: inline-block;
  transition: margin-left 0.15s ease-in;

  .tab-heading-content {
    display: flex;
    align-items: center;

    .icon {
      margin-right: 10px;
      font-size: 19px;
    }
  }

  .tab-link {
    vertical-align: text-top;
  }
  @include media("<tablet") {
    display: flex;
    flex-direction: column;
  }
}

.component-slider .slider-content button {
  display: inline-block;
}

.component-slider .caret {
  position: absolute;
  padding: 0;
  margin: 0;
  border: none;
  cursor: pointer;
  background-color: transparent;
  text-align: center;
  font-size: 16px;
  color: #fff;
  outline: none;
  @include media("<tablet") {
    display: none;
  }
}

.component-slider .caret-right {
  right: 0;
  bottom: 10px;

  .icon-slider {
    display: block;
    content: "\e907";
    font-family: "icomoon" !important;
  }
}

.component-slider .caret-left {
  transform: rotate(180deg);
  right: 0;
  top: 0;

  .icon-slider {
    display: block;
    content: "\e907";
    font-family: "icomoon" !important;
  }
}

/*custom styles*/
.menu-bar {
  border: 1px solid gray;
  width: 100%;
}

.menu-item {
  width: 100px;
  display: inline-block;
  padding: 15px 10px;
  cursor: pointer;
  text-transform: uppercase;
}

.menu-item:hover {
  background: #656565;
  color: #ffffff;
}

.checkboxes-list {
  overflow: hidden;

  label {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
}

.current-checkboxes {
  li {
    display: flex;
    align-items: center;
  }

  .icon-close {
    font-family: "icomoon" !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 12px;
    font-weight: 700;
    margin-right: 10px;
    color: $green;
    cursor: pointer;

    &:before {
      content: "\e908";
    }

    &:hover {
      color: $black;
    }
  }
}

.multi-tab-heading {
  font-size: 16px;
  padding: 13px 30px;
  color: $white;
  background-color: $green;
}
