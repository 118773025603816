@import "../../../../../../assets/scss/base/variables";
@import "../../../../../../assets/scss/vendors/include-media";

.modal-myLocation {
  padding: 40px 10px 0;
  @include media("<tablet") {
    padding-top: 10px;
  }
  .mb-5 {
    margin-bottom: 20px;
  }
  .row {
    align-items: flex-start;
  }
  .disable {
    opacity: 0.5;
  }
  h3 {
    font-size: 18px;
    color: #4a63a1;
    font-weight: 600;
    margin-bottom: 15px;
  }
  .fake-link {
    display: inline-block;
    margin-bottom: 25px;
    font-weight: 700;
    color: $brand;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
  .geolocation-holder {
    position: relative;
    padding: 0;
    background-color: transparent;
    .icon-map-pin {
      position: absolute;
      font-size: 24px;
      left: 20px;
      top: 50%;
      color: $white;
      transform: translateY(-50%);
    }
    .fake-input {
      padding: 11px;
      width: 100%;
      display: none;
      overflow: hidden;
      overflow-x: auto;
      .text {
        white-space: nowrap;
      }
    }
    .fake-input-btn {
      cursor: pointer;
      display: inline-block;
      padding: 11px 15px 11px 60px;
      width: 100%;
      font-size: 18px;
      font-weight: 700;
      background-color: $brand;
      color: $white;
      &:hover {
        opacity: 0.75;
      }
    }
    .loading {
      pointer-events: none;
      animation: blinking 2s infinite;
    }
    @keyframes blinking {
      0% {
        opacity: 1;
      }
      25% {
        opacity: 0.75;
      }
      50% {
        opacity: 0.5;
      }
      75% {
        opacity: 0.75;
      }
    }
    &.with-value {
      border: 1px solid $light-blue;
      background-color: $white;
      padding: 0 15px 0 60px;
      .icon-map-pin {
        color: $brand;
      }
      .fake-input {
        display: block;
      }
      .fake-input-btn {
        display: none;
      }
    }
  }

  .refresh-btn {
    padding: 0;
    margin-left: 20px;
  }
}
