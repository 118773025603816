@import "../../../../../assets/scss/base/variables";
@import "../../../../../assets/scss/vendors/include-media";
.selected-list-section {
  padding: 40px 30px 0;
  line-height: 1.45;
  margin-bottom: 20px;
  @include media("<tablet") {
    padding: 20px 20px 0;
  }
}
.primary-list {
  padding: 0;
  margin: 0;
  list-style: none;
  li {
    margin-bottom: 10px;
  }
  .list-heading {
    font-size: 16px;
    color: $blue;
    font-weight: 600;
  }
  .under-list {
    list-style: none;
    margin: 0;
    padding: 0;
    li {
      padding-left: 20px;
      position: relative;
      .icon {
        position: absolute;
        top: 5px;
        left: 0;
        margin: 0;
        padding: 1px;
        width: 13px;
        height: 13px;
        border-radius: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        line-height: 0;
        color: $white;
        &.icon-confirm {
          font-size: 7px;
          font-weight: 700;
          background-color: $green;
        }
        &.icon-close {
          font-weight: 400;
          font-size: 8px;
          background-color: $red;
        }
      }
    }
  }
}
.export-section {
  padding: 40px 30px 0;
  @include media("<tablet") {
    padding: 0 30px;
  }
  h1 {
    font-size: 25px;
    color: $blue;
  }
  hr {
    margin: 40px 0;
    @include media("<tablet") {
      display: none;
    }
  }
  .title-holder {
    margin-bottom: 15px;
    h3 {
      color: $blue;
      font-size: 16px;
      margin-bottom: 5px;
    }
    p {
      font-weight: 600;
      font-size: 14px;
    }
  }
}

.list-holder {
  padding: 20px 0;
  .list-export {
    padding: 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    margin: 0 -15px;
    .card {
      &.selected {
        .card-holder {
          background: $light-brand;
          color: $white;
        }
        .row-card {
          .card-title {
            color: $white;
          }
          .icon {
            color: $white;
          }
          .card-subtitle {
            color: $white;
          }
        }
      }
      &.mandatory {
        .card-holder {
          background-color: $green;
          color: $white;
        }
        .row-card {
          .card-title {
            color: $white;
          }
          .icon {
            color: $white;
          }
          .card-subtitle {
            color: $white;
          }
        }
      }
      .card-holder {
        transition:
          background-color 0.3s,
          color 0.3s;
        color: $dark-blue;
        background-color: #ffffff;
      }
    }
  }
  .card {
    width: 33.33%;
    padding: 0 15px;
    margin-bottom: 20px;
    @include media("<tablet") {
      width: 100%;
    }
  }
  .card:last-child {
    @include media("<tablet") {
      margin-bottom: 90px;
    }
  }
  .card-holder {
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.5);
    .row-card {
      padding: 20px 15px;
      display: flex;
      align-items: center;
      cursor: pointer;
      span {
        display: block;
      }
      .icon {
        font-size: 36px;
        font-weight: 400;
        margin-right: 15px;
        color: $blue;
      }
    }
  }
  .card-btn-row {
    display: flex;
    justify-content: space-between;
    background-color: #f1f3fc;
    .column {
      width: 50%;
      position: relative;
    }
    .column:not(:last-child) {
      border-right: 1px solid #cfd5ee;
    }
    button {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: transparent;
      border: none;
      padding: 12px 26px;
      width: 100%;
      text-align: center;
      color: $dark-blue;
      font-size: 12px;
      font-weight: 600;
      transition:
        color 0.3s,
        background-color 0.3s;
      height: 42px;
      .icon {
        transition: color 0.3s;
        margin-right: 5px;
        color: $green;
        font-size: 18px;
      }
      &:hover {
        background-color: $green;
        color: $white;
        .icon {
          color: $white;
        }
      }
    }
  }
}

.card-text-holder {
  .card-title {
    font-weight: 600;
    font-size: 14px;
    margin-bottom: 5px;
    letter-spacing: 0.04rem;
    color: $blue;
  }
  .card-subtitle {
    color: #7e97c5;
    font-size: 12px;
    font-style: italic;
    font-weight: 500;
  }
}

.btn-controller {
  list-style: none;
  padding: 10px 0 25px;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  @include media("<tablet") {
    padding: 10px 0 0;
  }
  li {
    padding-right: 25px;
    &:last-child {
      padding-right: 0;
    }
    .fake-link {
      color: $green;
      cursor: pointer;
      margin-bottom: 5px;
      display: flex;
      align-items: center;
      .btn-title {
        font-weight: 700;
        font-size: 14px;
        position: relative;
        &:after {
          content: "";
          position: absolute;
          bottom: -3px;
          left: 0;
          width: 0;
          height: 3px;
          background-color: $green;
          transition: width 0.3s;
        }
      }
      .icon {
        margin-right: 10px;
        font-size: 30px;
        font-weight: 400;
      }
      &:hover {
        .btn-title {
          &:after {
            width: 100%;
          }
        }
      }
    }
  }
}

.list-top-section {
  box-shadow: 0 26px 32px -42px rgba(0, 0, 0, 0.9);
  margin-bottom: 20px;
  @include media("<tablet") {
    box-shadow: none;
    margin-bottom: 0;
  }
}

.export-category-modal {
  header {
    display: flex;
    align-items: center;
    @include media("<tablet") {
      align-items: flex-start;
      justify-content: flex-start;
      flex-direction: column;
    }
    .left-header {
      display: flex;
      align-items: center;
      font-size: 14px;
      @include media("<tablet") {
        margin-bottom: 15px;
      }
      span.icon {
        margin-right: 15px;
        font-size: 25px;
      }
      .count-container {
        font-size: 12px;
        margin-top: 3px;
      }
    }
    .separator {
      margin: 0 15px;
      height: 30px;
      width: 1px;
      background-color: #fff;
      @include media("<tablet") {
        display: none;
      }
    }
    .right-header {
      &__title {
        font-size: 16px;
        font-weight: 500;
      }
      &__subtitle {
        margin-top: 5px;
        font-size: 14px;
      }
    }
  }
  .modal-content {
    padding: 10px 20px 20px;
    &__category-list {
      display: inline-block;
      width: 100%;
      background-color: #fff;
      padding: 35px;
      padding-bottom: 25px;
      .category-entry {
        float: left;
      }

      .category-entry-potential {
        font-size: small;
        margin-bottom: 10px;
        text-align: center;
      }
      .category-entry-potential-selected {
        color: #32a31a;
      }
      .category-entry-box {
        margin-right: 15px;
        padding: 8px 12px;
        border-radius: 25px;
        color: #4a63a1;
        position: relative;
      }
      .category-entry-box-bottom {
        margin-bottom: 10px;
      }
      .category-entry-selectable {
        cursor: pointer;
      }
      .category-entry-selected {
        background: #32a31a;
        color: #f9f9f9;
      }
      .category-entry-unselected {
        background: #b6c4dd;
        color: #4a63a1;
      }
      .checkboxes-wrapper {
        padding: 0;
        .checkboxes-list {
          padding: 0;
          li {
            display: inline-block;
            margin-bottom: 17px;
            label {
              background: #b6c4dd;
              padding: 7px 20px;
              border-radius: 25px;
              color: #4a63a1;
              margin-right: 12px;
              margin-left: 12px;
              &:hover,
              &.checked {
                background-color: $green;
                color: $white;
              }
              .custom-checkbox {
                width: 0;
                height: 0;
                display: inline-block;
                cursor: pointer;
                position: relative;
                background: none;
                border: none;
                margin-right: 0;
                transition: none;
                &:after {
                  opacity: 0;
                }
              }
            }
          }
        }
      }
    }
    p {
      font-size: 16px;
      color: $blue;
      font-weight: 700;
    }
    .selectAll {
      color: $green;
      font-weight: 700;
      font-size: 15px;
      float: right;
      margin: 10px 0;
      span {
        cursor: pointer;
      }
    }
    .radio-group {
      margin-bottom: 20px;
      label {
        color: $blue;
        font-weight: 700;
        font-size: 15px;
        margin-left: 10px;
      }
      p {
        margin-left: 23px;
        padding-top: 5px;
        color: $dark;
        font-size: 15px;
        font-weight: 500;
      }
      input[type="radio"] {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        display: inline-block;
        width: 15px;
        height: 15px;
        padding: 2px;
        background-clip: content-box;
        border: 1px solid $blue;
        border-radius: 50%;
      }

      input[type="radio"]:checked {
        background-color: $green;
      }
      &.disabled {
        cursor: not-allowed;
        input[type="radio"] {
          cursor: not-allowed;
          border-color: #ccc;
        }
        label {
          cursor: not-allowed;
          color: #ccc;
        }
      }
    }
    .actions-holder {
      margin: 25px 0;
      .btn {
        margin-right: 20px;
      }
      p {
        display: inline;
        color: $green;
        cursor: pointer;
        @include media("<tablet") {
          display: block;
          margin: 15px 0;
        }
      }
    }
    .modal-content-liasses {
      max-height: 235px;
      overflow: hidden;
      overflow-y: scroll;
    }
  }
}

.form-modal {
  .input-row {
    display: flex;
    flex-direction: column;
    margin-bottom: 25px;
    &.mb-2x {
      margin-bottom: 40px;
    }
  }
  p {
    color: $blue;
    font-weight: 600;
  }
  form {
    padding: 10px;
    max-width: 600px;
    label {
      display: inline-block;
      margin-bottom: 20px;
      line-height: 1;
      color: $blue;
      font-weight: 600;
      padding: 0 5px;
    }
    .input {
      padding: 15px;
      width: 100%;
      max-width: 600px;
      border-radius: 2px;
      border: 1px solid $green;
    }
  }
  .btn-row {
    display: flex;
    align-items: center;
    @include media("<tablet") {
      justify-content: space-around;
    }
    .btn {
      margin: 0 15px;
      cursor: pointer;
      &.disabled {
        pointer-events: none;
        opacity: 0.3;
      }
    }
  }
}
