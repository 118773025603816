@import "../../../assets/scss/base/variables";
@import "../../../assets/scss/vendors/include-media";
.table-holder {
  padding: 25px;
  .search-heading {
    padding: 10px 15px;
    background-color: $pale-blue;
    .search-table {
      position: relative;
      cursor: pointer;
      .icon-search {
        position: absolute;
        top: 50%;
        right: -3px;
        font-size: 24px;
        color: $green;
        transform: translate(-50%, -50%);
      }
      input {
        width: 40px;
        height: 40px;
        border-radius: 20px;
        border: none;
        transition: width 0.3s;
        cursor: pointer;
      }
      &.active {
        .icon-search {
          transform: translateY(-50%);
          right: 15px;
        }
        input {
          padding: 0 45px 0 20px;
          border-radius: 20px;
          width: 410px;
          @include media("<tablet") {
            width: 100%;
          }
        }
        &:hover {
          input {
            cursor: text;
            background-color: $white;
          }
        }
      }
      &:hover {
        input {
          background-color: $light-blue;
        }
      }
    }
  }
  .table-wrapper {
    box-shadow: 0px 0px 16px -5px rgba(0, 0, 0, 0.75);
    .white-borders {
      position: relative;
    }
    table {
      text-align-last: left;
      padding: 0 15px;
      width: 100%;
      overflow: hidden;
      thead {
        th {
          color: $blue;
          padding: 20px 20px;
          .icon {
            display: inline-block;
            vertical-align: middle;
            cursor: pointer;
            color: $green;
            font-size: 12px;
            font-weight: 700;
            margin-left: 15px;
            opacity: 0;
            &:hover {
              color: $blue;
            }
            &.visible {
              opacity: 1;
            }
          }
          &.asc {
            .icon {
              transform: rotate(0);
            }
          }
          &.desc {
            .icon {
              transform: rotate(180deg);
            }
          }
        }
      }
      th,
      td {
        padding: 15px;
        border-right: 1px solid $light-blue;
        z-index: 5;
        &:last-of-type {
          border-right: none;
        }
        p {
          margin: 0;
        }
      }
      tbody {
        background-color: $white;
        td {
          position: relative;
          border-bottom: 1px solid $light-blue;
          &:after {
            content: "";
            position: absolute;
            width: 100%;
            z-index: -1;
            height: 100%;
            left: 50%;
            transform: translateX(-50%);
            top: 0;
            bottom: 0;
          }
          &:first-child {
            width: 25%;
            @include media("<tablet") {
              width: 50%;
            }
            &:after {
              transform: none;
              left: auto;
              right: 0;
              width: calc(100% - 20px);
            }
          }
          &:last-child {
            width: 20%;
            &:after {
              transform: none;
              left: 0;
              right: auto;
              width: calc(100% - 20px);
            }
          }
          div.chip {
            display: flex;
            border-radius: 0.25rem;
            padding: 0.3rem 0.5rem;
            justify-content: center;
            align-items: center;
            margin: auto;
            font-size: 12px;

            &.SUCCEED {
              color: white;
              background-color: #8DD674;
            }

            &.FAILED {
              color: white;
              background-color: #F8786B;
            }

            &.PENDING {
              color: white;
              background-color: #FFB400;
            }

            &.REQUESTED {
              color: white;
              background-color: #FFB400;
            }
          }
        }
        tr {
          &:last-of-type {
            td {
              &:after {
                transform: none;
                z-index: -1;
                width: calc(100% + 4px);
                left: -2px;
                right: -2px;
                border-bottom: 2px solid $white;
              }
            }
          }
        }
      }
    }
  }
}

.btn-line {
  list-style: none;
  display: flex;
  padding: 0;
  margin: 0 -10px;
  ul {
    list-style: none;
    display: flex;
    padding: 0;
    margin: 0;
  }
  button,
  .action-btn {
    background-color: transparent;
    color: $green;
    border: none;
    padding: 0;
    margin: 0 7px;
    font-weight: 700;
    font-size: 17px;
    line-height: 25px;
    .icon {
      font-size: 25px;
    }
    .text {
      position: relative;
      &:after {
        content: "";
        display: block;
        width: 100%;
        height: 2px;
        background: $green;
        position: absolute;
        left: 0;
        bottom: -2px;
      }
    }
  }
  a {
    font-size: 25px;
  }
}

.table-section {
  padding: 0 0 50px;
  .btn-holder {
    display: flex;
    justify-content: flex-end;
    padding: 35px 25px 10px;
  }
}

#actions {
  pointer-events: none;
}

.not-sortable {
  pointer-events: none;
}

.pagination-holder {
  display: block;
  width: 100%;
  overflow: hidden;
  padding: 0 0 20px;
  .pagination {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: center;
    li {
      margin: 0 4px;
      a {
        display: inline-block;
        width: 32px;
        height: 32px;
        border-radius: 100%;
        text-align: center;
        line-height: 1;
        color: $dark-blue;
        font-size: 14px;
        display: flex;
        align-items: center;
        align-content: center;
        justify-content: center;
        .icon {
          font-size: 20px;
          line-height: 1;
          display: inline-block;
        }
        &:hover {
          background: $light-blue;
        }
      }
      &.disabled {
        color: $gray;
        opacity: 0.5;
        pointer-events: none;
        a {
          color: $gray;
        }
      }
      &.active {
        a {
          background: $green;
          color: $white;
        }
      }
    }
  }
}
