@import "../../../../../../assets/scss/base/variables";
@import "../../../../../../assets/scss/vendors/include-media";

.checkbox-item {
  padding-top: 8px;
  padding-bottom: 8px;
  .icon-checkbox-holder {
    .checkbox-content {
      .icon-scoring-green {
        border-color: $green;
        color: $green;
        align-items: flex-end;
        font-size: 66px;
        padding: 0;
        &:before {
          content: "\e99a";
        }
      }
      .icon-scoring-yellow {
        border-color: $yellow;
        color: $yellow;
        align-items: flex-end;
        font-size: 66px;
        padding: 0;
        &:before {
          content: "\e99a";
        }
      }
      .icon-scoring-orange {
        border-color: $orange;
        color: $orange;
        align-items: flex-end;
        font-size: 66px;
        padding: 0;
        &:before {
          content: "\e99a";
        }
      }
      .icon-scoring-red {
        border-color: $red-base;
        color: $red-base;
        align-items: flex-end;
        font-size: 66px;
        padding: 0;
        &:before {
          content: "\e99a";
        }
      }
    }
  }
}

.custom-checkbox-list {
  padding: 20px 0;
  align-items: stretch;
  justify-content: center;
  margin: 0 auto !important;
  .checkbox-item {
    @include media("<tablet") {
      margin-top: 15px;
    }
  }
}
.icon-checkbox-holder {
  cursor: pointer;
  text-align: center;
  position: relative;
  display: block;
  padding: 30px 10px 10px;
  border: 3px solid $green;
  background-color: $white;
  transition: border-color 0.3s;
  height: 100%;

  .custom-icon-checkbox {
    position: absolute;
    top: -9px;
    left: 50%;
    transform: translateX(-50%);
    width: 15px;
    height: 15px;
    display: inline-block;
    cursor: pointer;
    background: $white;
    border: 2px solid $green;
    transition:
      opacity 0.3s,
      border-color 0.3s,
      background-color 0.3s;
    &:after {
      content: "\e955";
      font-family: "icomoon" !important;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      position: absolute;
      background: transparent;
      opacity: 0;
      font-size: 11px;
      color: $white;
      transition: opacity 0.3s;
    }
    &.checked {
      border-color: $green;
      background-color: $green;
      &:after {
        opacity: 1;
      }
    }
    &:hover {
      background-color: $green;
      border-color: $green;
      opacity: 0.8;
    }
    input {
      visibility: hidden;
    }
  }

  .checkbox-content {
    .checkbox-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 90px;
      height: 90px;
      margin: 0 auto;
      border-radius: 100%;
      padding: 5px;
      border: 2px solid $dark-blue;
      font-size: 54px;
      margin-bottom: 15px;
      color: $dark-blue;
    }
    .checkbox-title {
      margin-bottom: 20px;
      display: block;
      text-align: center;
      font-size: 18px;
      font-weight: 600;
      line-height: 1.2;
      color: $dark-blue;
    }
  }

  &.checked-holder {
    border-color: $green;

    .checkbox-icon {
      border-color: $green;
      color: $green;
      transition:
        color 0.3s,
        border-color 0.3s;
    }
    .custom-icon-checkbox {
      &:after {
        opacity: 1;
      }
    }
  }
}

.checkbox-icon {
  font-family: "icomoon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.checkbox-title {
  color: $dark-blue;
  font-weight: 600;
  font-size: 18px;
  font-family: $base-font-sans-serif;
  line-height: 1.2;
}
.modal-icon-checkboxes {
  &.exclude {
    .icon-checkbox-holder {
      &.checked-holder {
        border-color: $red;
      }
    }
    .icon-checkbox-holder {
      .custom-icon-checkbox {
        &:hover {
          background-color: $red;
          border-color: $red;
        }
        &.checked {
          border-color: $red;
          background-color: $red;
        }
      }
    }
  }
}
