$breakpoints: (
  "xs-phone": 320px,
  "phone": 480px,
  "tablet": 768px,
  "desktop": 1024px,
  "widescreen": 1200px,
);

// keywords
$media-expressions: (
  "screen": "screen",
  "print": "print",
  "handheld": "handheld",
  "landscape": "(orientation: landscape)",
  "portrait": "(orientation: portrait)",
  "retina2x": "(-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi)",
  "retina3x": "(-webkit-min-device-pixel-ratio: 2.5), (min-resolution: 240dpi)",
);

// ligatured operators ≥ ≤

// @import url('https://fonts.googleapis.com/css?family=Raleway:300,400,500,600');
@import url("https://fonts.googleapis.com/css?family=Open+Sans:300|Raleway:300,400,500,600");

@import "./assets/scss/vendors/normalize";
@import "./assets/scss/vendors/include-media";
@import "./assets/scss/vendors/icomoon";

@import "./assets/scss/base/functions";
@import "./assets/scss/base/variables";
@import "./assets/scss/base/mixins";
@import "./assets/scss/base/helpers";
@import "./assets/scss/base/reset";
@import "./assets/scss/base/typography";
@import "./assets/scss/base/forms";

@import "./assets/scss/components/listTable";
@import "./assets/scss/components/customRadio";

@import "./assets/scss/global";

@import "~leaflet/dist/leaflet.css";

.menu {
  transition: transform 0.3s;
  transform: translateX(-100%);
  @include media(">=tablet") {
    transform: none;
  }
  &.show {
    transform: translateX(0);
  }
}
.mobile-opener {
  position: fixed;
  top: 7px;
  left: 10px;
  z-index: 999;
  cursor: pointer;
  background-color: transparent;
  border: none;
  display: block;
  width: 49px;
  height: 40px;
  border-radius: 3px;
  z-index: 100;
  @include media(">=tablet") {
    display: none;
  }
  &:hover {
    opacity: 0.9;
  }

  &.nav-active {
    span {
      opacity: 0;
    }
    &:before,
    &:after {
      transform: rotate(45deg);
      top: 50%;
      left: 15%;
      right: 15%;
    }
    &:after {
      transform: rotate(-45deg);
    }
  }

  span,
  &:before,
  &:after {
    background: #fff;
    border-radius: 4px;
    position: absolute;
    top: 50%;
    left: 25%;
    right: 25%;
    height: 3px;
    margin-top: 0;
    transition: all 0.3s linear;
  }
  &:before,
  &:after {
    content: "";
    top: 33%;
  }
  &:after {
    top: 67%;
  }
}
