@import "../../../../../../assets/scss/base/variables";
.modal-icon-radio {
  padding: 20px 0;
  max-width: 660px;
  margin: 0 auto;
  .custom-icon-radio {
    input {
      visibility: hidden;
      width: 0;
      height: 0;
    }
  }
  .radio-icon-holder {
    cursor: pointer;
    padding: 20px 5px 10px;
    position: relative;
    display: block;
    height: 100%;
    border: 3px solid $green;
    text-align: center;
    transition: border-color 0.3s;
    .radio-content {
      .radio-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100px;
        margin: 0 auto;
        max-width: 100px;
        border-radius: 100%;
        border: 2px solid #37444e;
        font-size: 44px;
        margin-bottom: 15px;
        color: #37444e;
      }
      .radio-title {
        margin-bottom: 20px;
        display: block;
        text-align: center;
        font-size: 18px;
        font-weight: 600;
        line-height: 1.2;
        color: $dark-blue;
      }
    }
    &.active {
      border-color: $green;
      .radio-icon {
        border-color: $green;
        color: $green;
        transition:
          color 0.3s,
          border-color 0.3s;
      }
      .custom-icon-radio {
        &:after {
          opacity: 1;
        }
      }
    }
  }
  &.exclude {
    .radio-icon-holder {
      &.active {
        border-color: $red;
        .radio-icon {
          border-color: $red;
          color: $red;
        }
      }
    }
    .custom-icon-radio {
      &:after {
        background-color: $red;
      }
    }
  }
}
.custom-icon-radio {
  position: absolute;
  width: 18px;
  height: 18px;
  border: 2px solid $light-blue;
  border-radius: 100%;
  top: -10px;
  left: 50%;
  transform: translateX(-50%);
  background-color: $white;
  &:after {
    content: "";
    width: 10px;
    height: 10px;
    background-color: $green;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 100%;
    opacity: 0;
    transition:
      opacity 0.3s,
      background-color 0.3s;
  }
  &:hover {
    &:after {
      background-color: $green;
      opacity: 0.5;
    }
  }
}
.custom-radio-list {
  justify-content: center;
  align-items: stretch;
}
.radio-item {
  margin-bottom: 20px;
}
