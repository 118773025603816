html {
  box-sizing: border-box;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}

* {
  max-height: 1000000px;
}

body {
  color: $base-text-color;
  background: $base-background-color;
  font: #{$font-size-base}/#{$line-height-base} $base-font-family;
  min-width: $base-min-width;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-variant-numeric: lining-nums;
}

// adaptive images
img {
  max-width: 100%;
  height: auto;
  vertical-align: top;
}

// google map fix
.gm-style img {
  max-width: none;
}

input:focus,
textarea:focus,
select:focus,
button:focus {
  outline: none;
}
