@import "../../../../../../assets/scss/base/variables";

.checkboxes-list {
  .react-checkbox-tree {
    ol {
      padding: 0;
      margin: 0;
      list-style: none;
      li {
        margin: 0 0 5px;
        li {
          padding: 0 0 0 30px;
        }
        .rct-text {
          display: flex;
          span.rct-collapse {
            display: none;
          }
        }
      }
    }
    // li li label {

    // }
    .rct-title {
      font-size: 14px;
      line-height: 16px;
      display: inline;
      vertical-align: top;
    }
    .rct-collapse {
      display: inline-block;
      margin: 0 4px 0 0;
      width: 15px;
      height: 15px;
      background: none;
      border: none;
      vertical-align: top;
      // padding: 0;
      position: relative;
      .rct-icon {
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: block;
        text-align: center;
        line-height: 16px;
        position: absolute;
        background: transparent;
        font-size: 12px;
        font-weight: 700;
        color: $light-blue;
        transition: opacity 0.3s;
        font-family: "icomoon" !important;
        &.rct-icon-expand-close {
          &:after {
            opacity: 1;
            content: "\e91c";
            display: block;
            transform: rotate(-90deg);
          }
        }
        &.rct-icon-expand-open {
          &:after {
            opacity: 1;
            content: "\e91c";
            display: block;
          }
        }
      }
      &:focus {
        outline: none;
      }
    }
    label {
      display: inline-block;
    }
    input {
      width: 0;
      height: 0;
      visibility: hidden;
    }
    .rct-checkbox {
      width: 15px;
      height: 15px;
      display: inline-block;
      cursor: pointer;
      position: relative;
      background: $white;
      margin-right: 5px;
      transition:
        opacity 0.3s,
        border-color 0.3s,
        background-color 0.3s;
      .rct-icon {
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: block;
        text-align: center;
        line-height: 15px;
        position: absolute;
        background: transparent;
        font-size: 10px;
        color: $white;
        transition: opacity 0.3s;
        font-family: "icomoon" !important;
        &.rct-icon-check {
          border-color: $green;
          background-color: $green;
          &:after {
            opacity: 1;
            content: "\e955";
            display: block;
            font-size: 9px;
            font-weight: 700;
          }
        }
        &.rct-icon-half-check {
          border-color: $green;
          background-color: $green;
          opacity: 0.6;
          &:after {
            font-weight: 700;
            opacity: 1;
            content: "\e90c";
            display: block;
            line-height: 15px;
            font-size: 12px;
          }
        }
        &.rct-icon-uncheck {
          border: 2px solid $light-blue;
        }
      }
      // &.checked {

      // }
      &:hover {
        background-color: $green;
        border-color: $green;
        opacity: 0.8;
      }
    }
  }
}
