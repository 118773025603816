@import "../../../assets/scss/base/variables";

.custom-checkbox {
  width: 15px;
  height: 15px;
  display: inline-block;
  cursor: pointer;
  position: relative;
  background: $white;
  border: 2px solid $light-blue;
  margin-right: 15px;
  transition:
    opacity 0.3s,
    border-color 0.3s,
    background-color 0.3s;
  &:after {
    content: "\e955";
    font-family: "icomoon" !important;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    background: transparent;
    opacity: 0;
    font-size: 10px;
    color: $white;
    font-weight: 700;
    transition: opacity 0.3s;
  }
  &.checked {
    border-color: $green;
    background-color: $green;
    &:after {
      opacity: 1;
    }
  }
  &:hover {
    background-color: $green;
    border-color: $green;
    opacity: 0.8;
  }
  input {
    visibility: hidden;
  }
}
