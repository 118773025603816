// Typography

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6,
.h {
  font-family: $headings-font-family;
  font-weight: bold;
  margin: 0 0 0.5em;
  color: $headings-color;
}

h1,
.h1 {
  font-size: $h1-font-size;
  font-weight: 600;

  @include media(">=desktop") {
    font-size: 25px;
  }
}

h2,
.h2 {
  font-size: 20px;
  color: $blue;
  @include media(">=desktop") {
    font-size: 25px;
    margin-bottom: 7px;
  }
}
h3,
.h3 {
  font-size: 12px;
  color: $dark-blue;
  font-weight: 400;
  @include media(">=desktop") {
    font-size: 14px;
  }
}

h4,
.h4 {
  font-size: $h4-font-size;
}

h5,
.h5 {
  font-size: $h5-font-size;
}

h6,
.h6 {
  font-size: $h6-font-size;
}

p {
  margin: 0 0 1em;
}

a {
  color: $base-link-color;
  text-decoration: none;
}
