@import "../../../../../../assets/scss/base/variables";
.modal-switch {
  margin: 15px 0;
  .slider-container {
    display: flex;
    align-items: center;
    justify-content: center;
    &.active {
      .rangeslider {
        background: $green;
        &__fill {
          background: $green;
        }
      }
    }
    .rangeslider {
      background: gray;
      margin: 20px;
      &__fill {
        background: gray;
      }
    }
  }
}
