@import "../../../../../../assets/scss/base/variables";
.reset-btn {
  position: absolute;
  top: 20px;
  right: 20px;
  color: $green;
  border: none;
  background: none;
  display: flex;
  align-items: center;
  cursor: pointer;
  .refresh-btn {
    font-weight: 600;
    font-size: 14px;
    position: relative;
  }
  .icon-refresh {
    margin-right: 10px;
    font-size: 21px;
    font-weight: 400;
    position: relative;
  }
}
