@import "../../../../../assets/scss/base/variables";
@import "../../../../../assets/scss/vendors/include-media";
.cards-holder {
  overflow: auto !important;
  padding: 30px 25px 30px 15px;
  align-items: stretch;
  .card-item {
    margin-bottom: 20px;
    .card-content:hover {
      cursor: pointer;
    }
    .card-content {
      background-color: $white;
      padding: 20px;
      height: 100%;
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: center;

      h3 {
        font-size: 12px;
        font-weight: 700;
        color: $green;
        margin-bottom: 15px;
      }
      h4 {
        span {
          font-size: 14px;
          margin-right: 5px;
        }
        margin-bottom: 15px;
        font-size: 12px;
        color: $pale-blue;
      }
      h5 {
        font-size: 12px;
        color: $blue;
        margin-bottom: 0;
      }
    }
  }
}
.icon-wizard-step1-map {
  &:before {
    content: "\e917";
  }
}
.icon-wizard-step1-details {
  &:before {
    content: "\e918";
  }
}
.established-tabs {
  .tab-heading {
    padding-right: 35px;
    button {
      font-size: 16px;
      padding: 15px 5px 15px;
      margin-right: 30px;
      &:last-of-type {
        margin-right: 0;
      }
    }
    @include media("<tablet") {
      display: none;
    }
  }
}
.established-section {
  padding: 25px 25px 0;
  .text-holder {
    margin-bottom: 0;
    line-height: 1.2;
    h3 {
      margin-bottom: 5px;
      @include media("<tablet") {
        display: none;
      }
    }
    h1 {
      color: $blue;
      margin-bottom: 7px;
      @include media("<tablet") {
        font-size: 20px;
      }
    }
  }
}
.map-tab {
  padding: 20px 0 0;
  .card-item {
    z-index: 0;
    margin-bottom: 16px;
  }
  .row {
    overflow: auto;
    padding: 0 20px 25px;
    margin-left: 0px;
    margin-right: 0px;
    flex-direction: row;
    justify-content: left;
    align-items: stretch; // <-- stretch vertical (default value, can be omitted here)
    align-content: center;
    .with-bg {
      padding: 0 10px 0px;
      min-height: 100%;
    }
  }
  .card-item.chart-item:last-child {
    @include media("<tablet") {
      margin-bottom: 60px;
    }
  }
}
.card-item.card-map {
  @include media("<tablet") {
    display: none;
  }
}
.straight {
  align-items: stretch;
}
